import React, { FunctionComponent } from "react"
import * as Styled from "./MobileMenu.styled"
import menuLinks from "./MenuLinks"
import useScrollTo from "../../../../hooks/useScrollTo"

interface MobileMenuProps {
  isMenuOpen: boolean
  handleMenuToggle: () => void
}

const linksVariants = {
  visible: {
    transition: {
      staggerChildren: 0.05,
      delayChildren: 0.1,
    },
  },
}

const linkVariants = {
  visible: {
    x: 0,
    opacity: 1,
  },

  hidden: {
    x: 100,
    opacity: 0,
  },
}

const MobileMenu: FunctionComponent<MobileMenuProps> = ({
  isMenuOpen,
  handleMenuToggle,
}) => {
  const scrollToForm = useScrollTo("formularz")
  return (
    <Styled.Menu isMenuOpen={isMenuOpen}>
      <Styled.Container>
        <Styled.Links variants={linksVariants}>
          {menuLinks.map((link, index) => (
            <Styled.Link
              key={index}
              href={link.address}
              onClick={handleMenuToggle}
              variants={linkVariants}
            >
              {link.title}
            </Styled.Link>
          ))}
          <Styled.CustomButton
            variants={linkVariants}
            onClick={() => {
              handleMenuToggle()
              scrollToForm()
            }}
          >
            Subskrybuj
          </Styled.CustomButton>
        </Styled.Links>
      </Styled.Container>
    </Styled.Menu>
  )
}

export default MobileMenu
