import React, { ReactElement, FunctionComponent, useState } from "react"
import * as Styled from "./FaqSection.styled"
import { Header, Subheader, Text, Underlined } from "../../Typography"
import { Dotted } from "../../Typography"
import PlusIcon from "../../../icons/PlusIcon"
import Accordion from "./Accordion"

interface FaqProps {}

type FaqType = {
  question: string
  answear: React.ReactElement | string
}

const FaqSection: FunctionComponent<FaqProps> = props => {
  const questions: FaqType[] = [
    {
      question: "Ile trwa subskrybcja?",
      answear:
        "Subskrypcja SerioIT oraz okres rozliczeniowy obowiązuje przez 1 miesiąc, zupełnie jak w Netfliksie.",
    },
    {
      question: "Czy muszę podpiąć kartę kredytową?",
      answear:
        "Wystarczy fakturka. Na koniec miesiąca wystawimy Ci fakturę z odroczonym terminem płatności.",
    },
    {
      question: "Czy mogę zapłacić za rok z góry?",
      answear:
        "Żyjemy chwilą! W ramach naszej subskrypcji obowiązuje rozliczenie miesięczne.",
    },
  ]

  return (
    <Styled.Section>
      <Styled.Container>
        <Styled.Box>
          <Styled.Header>
            Najczęściej zadawane <Dotted>pytania</Dotted>
          </Styled.Header>
          <Styled.Subheader>
            Jeszcze jakieś pytania? Odpowiemy!
          </Styled.Subheader>
        </Styled.Box>
        <Styled.Box>
          {questions.map((item, index) => (
            <Accordion
              key={index}
              question={item.question}
              answear={item.answear}
            />
          ))}
        </Styled.Box>
      </Styled.Container>
    </Styled.Section>
  )
}

export default FaqSection
