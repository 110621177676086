import styled, { css } from "styled-components"
import { AppContainer, AppSection, mediaUp } from "../../../styles/Mixins"
import ThemeStyle from "../../../styles/ThemeStyle"
import { motion } from "framer-motion"

export const Section = styled.div`
  ${AppSection};
  position: relative;
`
export const Container = styled(motion.div)`
  ${AppContainer};
  display: grid;
  transform: translateY(4rem);
  gap: 5rem;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      grid-template-columns: 3fr 2fr;
      grid-template-rows: 1fr 1fr;
    `
  )}
`

export const ImageWrapper = styled(motion.div)``
export const ImageWrapperOne = styled(ImageWrapper)`
  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column: 1;
    `
  )}
`
export const ImageWrapperTwo = styled(ImageWrapper)`
  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      grid-column: 2/3;
      grid-row: 1;
    `
  )}
`
export const ImageWrapperThree = styled(ImageWrapper)`
  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      grid-column: 2/3;
      grid-row: 2;
    `
  )}
`

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20rem;
  background-color: ${ThemeStyle.backgrounds.light};
  z-index: -1;
`
