import React, { FunctionComponent } from "react"
import { Header, Underlined } from "../../Typography"
import * as Styled from "./OfferSection.styled"
import Button from "../../Button"
import Circle from "./Circle"
import useScrollTo from "../../../hooks/useScrollTo"

type Props = {}

const OfferSection: FunctionComponent<Props> = props => {
  const scrollToForm = useScrollTo("formularz")

  return (
    <Styled.Section id="oferta">
      <Styled.Container>
        <Header as={"h2"}>
          Najmniej <br /> spersonalizowana oferta
        </Header>
        <Header as={"h2"}>
          Uwierzcie nam, <Underlined>to działa.</Underlined>
        </Header>
        <Styled.Content>
          <Styled.Motto>
            My zajmujemy się wszystkim, a&nbsp;Ty masz spokój i&nbsp;pewność, że
            IT w&nbsp;Twojej firmie działa, jak trzeba.
          </Styled.Motto>
          <Styled.Benefits>
            <Styled.Benefit>
              <Styled.BenefitSubheader>01</Styled.BenefitSubheader>
              jeden pakiet
            </Styled.Benefit>
            <Styled.Benefit>
              <Styled.BenefitSubheader>02</Styled.BenefitSubheader>
              proste zasady
            </Styled.Benefit>
            <Styled.Benefit>
              <Styled.BenefitSubheader>03</Styled.BenefitSubheader>
              bez ukrytych kosztów licencyjnych
            </Styled.Benefit>
            <Styled.Benefit>
              <Styled.BenefitSubheader>04</Styled.BenefitSubheader>
              bez żadnych gwiazdek*
            </Styled.Benefit>
            <Styled.Note>* serio, u nas działa.</Styled.Note>
          </Styled.Benefits>
          <Button onClick={scrollToForm}>Subskrybuj</Button>
        </Styled.Content>
      </Styled.Container>
      <Styled.CircleConatiner>
        <Circle />
      </Styled.CircleConatiner>
    </Styled.Section>
  )
}

export default OfferSection
