import styled, { css } from "styled-components"
import { mediaUp } from "../../../../../styles/Mixins"
import ThemeStyle from "../../../../../styles/ThemeStyle"

const Icon = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  height: 30rem;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      width: 100%;
      height: unset;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      padding: 3rem;
      /* border: 1px solid orange; */
    `
  )}
  img, .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`

export default Icon
