import React, { FunctionComponent } from "react"

type Props = {}

const Crossed: FunctionComponent<Props> = props => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        style={{ stroke: "none" }}
        d="M21.1957 18.9276C21.3726 18.803 21.5423 18.6685 21.7041 18.5247C22.7233 17.5155 23.7327 16.4952 24.7532 15.486C25.2183 15.0248 25.441 14.488 25.2715 13.8409C24.9524 12.6197 23.5063 12.2701 22.56 13.1962C21.4641 14.2686 20.3904 15.3633 19.3056 16.4481C19.2115 16.5423 19.1113 16.6303 18.958 16.7742C18.8071 16.6006 18.6784 16.4357 18.5324 16.2882C17.5206 15.2703 16.5013 14.2599 15.4919 13.2396C15.0306 12.7734 14.4949 12.5528 13.848 12.7214C12.6271 13.04 12.2783 14.488 13.2035 15.4327C14.276 16.5287 15.3707 17.6048 16.4543 18.6896C16.5483 18.7838 16.6362 18.883 16.7796 19.0355C16.6065 19.188 16.4419 19.3157 16.2947 19.4632C15.2767 20.4748 14.2673 21.4939 13.2468 22.5031C12.7817 22.9643 12.5591 23.5011 12.7285 24.1483C13.0477 25.3694 14.4949 25.719 15.44 24.7929C16.536 23.7193 17.6097 22.6258 18.6945 21.541C18.7885 21.4468 18.8887 21.3588 19.0656 21.1914C19.1902 21.3681 19.3244 21.5378 19.4676 21.6997C20.4782 22.7188 21.4987 23.7317 22.513 24.7483C22.9744 25.2157 23.51 25.4364 24.157 25.2678C25.3779 24.9479 25.7267 23.5011 24.8015 22.5564C23.729 21.4605 22.6343 20.3843 21.5507 19.2995C21.4517 19.2053 21.3639 19.1061 21.1957 18.9276Z"
        fill="white"
      />
      <path
        style={{ stroke: "none" }}
        d="M0 17.8813C0.0989583 17.1486 0.169465 16.4122 0.300585 15.6857C1.08236 11.3465 3.1345 7.70287 6.42239 4.77579C9.46041 2.07063 13.0242 0.477527 17.0691 0.09692C22.7208 -0.433699 27.6836 1.22634 31.9029 5.05597C32.1161 5.24438 32.3192 5.44384 32.5115 5.65354C32.7831 5.94959 32.9316 6.33845 32.9266 6.74058C32.9216 7.14272 32.7635 7.52775 32.4846 7.81691C32.2057 8.10608 31.8272 8.27753 31.4263 8.2962C31.0255 8.31487 30.6327 8.17935 30.3283 7.91735C29.5292 7.25656 28.7808 6.52138 27.9297 5.93373C24.5986 3.64761 20.9049 2.78597 16.9021 3.30915C12.8201 3.84349 9.44929 5.74529 6.83679 8.89429C3.90143 12.4326 2.72135 16.5411 3.32252 21.1084C3.85566 25.191 5.76432 28.5669 8.91491 31.1704C12.4539 34.1024 16.5619 35.2888 21.1276 34.669C25.7848 34.0379 29.4302 31.6935 32.0984 27.8503C33.8574 25.3236 34.748 22.4821 34.8197 19.3951C34.8222 19.1973 34.8358 18.9999 34.8605 18.8037C34.9632 18.0599 35.5038 17.5044 36.2014 17.4263C36.5598 17.3754 36.9247 17.4496 37.2349 17.6365C37.5451 17.8235 37.7816 18.1117 37.9048 18.4529L38.0012 18.6897V20.1005C37.9703 20.3683 37.9406 20.6348 37.9097 20.9014C37.4557 24.9926 35.8947 28.5991 33.1387 31.6588C30.212 34.9058 26.5827 36.9328 22.2743 37.6977C21.5593 37.8217 20.8344 37.8961 20.1145 37.9928H17.888C17.794 37.9653 17.698 37.945 17.601 37.932C14.6675 37.747 11.8231 36.8492 9.31321 35.3161C4.39127 32.2985 1.36191 27.9482 0.298109 22.2565C0.165752 21.5448 0.0989607 20.8196 0.00247637 20.1005L0 17.8813Z"
        fill="#363636"
      />
      <path
        style={{ stroke: "none" }}
        d="M21.1956 18.9276C21.3639 19.1061 21.4517 19.2053 21.5457 19.2995C22.6293 20.3843 23.724 21.4605 24.7965 22.5564C25.7218 23.5011 25.3729 24.9479 24.152 25.2678C23.5051 25.4364 22.9695 25.2157 22.5081 24.7483C21.4987 23.7292 20.4782 22.7188 19.4688 21.6997C19.3256 21.5378 19.1914 21.3681 19.0668 21.1914C18.8887 21.3588 18.7885 21.4468 18.6957 21.541C17.6109 22.6258 16.5372 23.7193 15.4412 24.7929C14.4962 25.719 13.0489 25.3694 12.7298 24.1483C12.5603 23.5011 12.7829 22.9643 13.2481 22.5031C14.2686 21.4939 15.2779 20.4748 16.296 19.4632C16.4432 19.3157 16.6077 19.188 16.7809 19.0355C16.6374 18.883 16.5496 18.7875 16.4555 18.6896C15.372 17.6048 14.2772 16.5287 13.2048 15.4327C12.2795 14.488 12.6283 13.04 13.8492 12.7214C14.4962 12.5528 15.0318 12.7734 15.4932 13.2396C16.5025 14.2599 17.5218 15.2703 18.5337 16.2882C18.6796 16.4357 18.8083 16.6006 18.9592 16.7742C19.1126 16.6303 19.2066 16.5423 19.3068 16.4481C20.3916 15.3633 21.4653 14.2686 22.5613 13.1962C23.5076 12.2701 24.9536 12.6197 25.2727 13.8409C25.4422 14.488 25.2195 15.0248 24.7544 15.486C23.7339 16.4952 22.7246 17.5155 21.7053 18.5247C21.5431 18.6685 21.373 18.8031 21.1956 18.9276Z"
        fill="#363636"
      />
    </svg>
  )
}

export default Crossed
