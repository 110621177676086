import styled, { css } from "styled-components"
import { AppContainer, AppSection, mediaUp } from "../../../styles/Mixins"
import ThemeStyle from "../../../styles/ThemeStyle"

export const Section = styled.section`
  ${AppSection};
  background-color: ${ThemeStyle.backgrounds.dark};
  color: ${ThemeStyle.colors.textInverted};
  padding-top: 11rem;
  padding-bottom: 11rem;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      padding-top: 22rem;
      padding-bottom: 22rem;
    `
  )}
`

export const Container = styled.div`
  ${AppContainer};
`

export const Benefits = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaUp(
    ThemeStyle.breakpoints.md,
    css`
      margin-top: 17rem;
      flex-direction: row;
    `
  )}
`

export const Benefit = styled.div`
  padding-bottom: 6rem;
  border-bottom: 1px solid ${ThemeStyle.colors.border};
  margin-right: 5rem;
  margin-top: 5rem;

  ${mediaUp(
    ThemeStyle.breakpoints.md,
    css`
      margin-right: 14rem;
      margin-top: 0;
      padding-bottom: 8.2rem;
    `
  )}
`
export const BenefitNumber = styled.div`
  color: ${ThemeStyle.colors.main};
  font-size: 4.8rem;
`
export const BenefitText = styled.div`
  font-size: 1.8rem;
  line-height: 2.8rem;
  padding-top: 3rem;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      padding-top: 6.5rem;
    `
  )}
`
