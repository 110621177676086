import React, { FunctionComponent } from "react"
import * as Styled from "./SubscriptionContentSection.styled"
import { Header, Subheader, Underlined } from "../../Typography"
import * as Card from "../../Card/Card"
import * as Icons from "./Icons"

type Props = {}

const SubscriptionContentSection: FunctionComponent<Props> = props => {
  return (
    <Styled.Section>
      <Styled.Container>
        <Subheader>Nie komplikujmy sobie życia</Subheader>
        <Header>
          Co zawiera Twoja <Underlined>subskrypcja?</Underlined>
        </Header>
        <Styled.Columns>
          <Styled.Column>
            <Card.default
              icon={<Icons.People />}
              text={`Centralne zarządzanie uprawnieniami kont pracowników.`}
              hiddenText="Stworzymy niezawodne centrum dowodzenia Twoim IT."
            />
            <Card.default
              icon={<Icons.Padlock />}
              text={`Bezpieczne dwuetapowe uwierzytelnianie. Jedno hasło i${"\u00A0"}konto do logowania na komputerze i${"\u00A0"}we wszystkich narzędziach.`}
              hiddenText="Bezpieczne czy łatwe do zapamiętania? U nas wystarczy Ci jedno mocne hasło.
"
            />
            <Card.default
              icon={<Icons.Fire />}
              text="Wdrożenie procedur odzyskiwania danych po katastrofie (disaster recovery)."
              hiddenText="Niestraszny nam żaden pożar ani katastrofa.
"
            />
            <Card.default
              icon={<Icons.Microsoft />}
              text="Integracja ze środowiskiem Microsoft 365."
              hiddenText={`Podążamy za trendami. Prosto, wygodnie i${"\u00A0"}intuicyjnie. `}
            />
          </Styled.Column>
          <Styled.Column>
            <Card.default
              icon={<Icons.Circles />}
              text={`Kompleksowa, ustandaryzowana i${"\u00A0"}sprawna obsługa informatyczna.`}
              hiddenText="Standardowo czuwamy nad wszystkim. "
            />
            <Card.default
              icon={<Icons.Mail />}
              text={`Zintegrowany system mailingowy wspierający onboarding nowych pracowników i${"\u00A0"}alerty bezpieczeństwa.`}
              hiddenText={`ALERT SERIO IT. Uwaga! W${"\u00A0"}ciągu dnia prognozowana jest stabilna sytuacja w${"\u00A0"}Twojej firmie. Zostań w${"\u00A0"}biurze.`}
            />
            <Card.default
              icon={<Icons.Sheet />}
              text={`Cykliczne, szczegółowe raportowanie i${"\u00A0"}optymalizacja systemu.`}
              hiddenText="Mamy lepsze akta niż IPN."
            />
            <Card.default
              icon={<Icons.Crossed />}
              text={`Nowoczesny system monitorujący środowisko online i${"\u00A0"}wykrywający bieżące błędy w${"\u00A0"}infrastrukturze IT.`}
              hiddenText="Psst...
We are watching you."
            />
          </Styled.Column>
          <Styled.Column>
            <Card.default
              icon={<Icons.Atom />}
              text="Zintegrowany ekosystem umożliwiający pracę zdalną."
              hiddenText="Pracownik na home office to wypoczęty pracownik.
"
            />
            <Card.default
              icon={<Icons.Globe />}
              text={`Bezpieczna sieć kablowa i${"\u00A0"}sieć Wi-Fi.`}
              hiddenText={`
                Nie wykryto żadnego zagrożenia. Twoja sieć jest w${"\u00A0"}pełni bezpieczna.
              `}
            />
            <Card.default
              icon={<Icons.Smile />}
              text={`
                Zintegrowany system aktualizacji sprzętu i${"\u00A0"}infrastruktury IT.
              `}
              hiddenText="U nas nic nie zginie. "
            />
            <Card.default
              icon={<Icons.Umbrella />}
              text={`Dostosowanie infrastruktury IT do przepisów RODO i${"\u00A0"}skuteczna polityka bezpieczeństwa.`}
              hiddenText="RODO? To taka wyspa?"
            />
          </Styled.Column>
        </Styled.Columns>
      </Styled.Container>
    </Styled.Section>
  )
}

export default SubscriptionContentSection
