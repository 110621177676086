import styled, { css } from "styled-components"
import {
  AppContainer,
  AppSection,
  mediaUp,
  DesktopHidden,
  MobileHidden,
} from "../../../styles/Mixins"
import ThemeStyle from "../../../styles/ThemeStyle"
import { Subheader as BaseSubheader, Text } from "../../Typography"
import { motion } from "framer-motion"

export const Section = styled.section`
  background: ${ThemeStyle.backgrounds.white};
  ${AppSection};
  padding-bottom: 5rem;
  margin-top: 8rem;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      padding-bottom: 10rem;
      margin-top: 0rem;
    `
  )};
`
export const Container = styled.div`
  ${AppContainer};
  padding: 10rem 0rem;
  color: ${ThemeStyle.colors.text};
`

export const Subheader = styled(BaseSubheader)`
  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      margin-bottom: 5rem;
    `
  )};
`

export const TextBox = styled.div`
  margin-left: 0;
  margin-top: 4rem;

  ${mediaUp(
    ThemeStyle.breakpoints.md,
    css`
      margin: 12rem 0;
      margin-left: 10%;
    `
  )};

  ${mediaUp(
    ThemeStyle.breakpoints.lg,
    css`
      margin-left: 30%;
    `
  )};
`
export const WhyText = styled(Text)`
  font-size: 1.8rem;
  line-height: 2.8rem;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 2.4rem;
      line-height: 4.6rem;
    `
  )};
`

export const Benefits = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr;
  gap: 4rem;
  margin-top: 10rem;
  margin-bottom: 5rem;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      grid-template-columns: repeat(2, 1fr);
      gap: 8rem;
    `
  )};

  ${mediaUp(
    ThemeStyle.breakpoints.lg,
    css`
      grid-template-columns: repeat(4, 1fr);
      gap: 8rem;
    `
  )};
`
export const Benefit = styled(motion.div)`
  padding: 3rem 0rem;
  border-bottom: 2px solid ${ThemeStyle.colors.border};
  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      padding: 2rem 0rem;
      border-bottom: 2px solid ${ThemeStyle.colors.border};
      width: 100%;
    `
  )};
`

export const BenefitNumber = styled.span`
  color: ${ThemeStyle.colors.text};
  background-color: transparent;
  font-size: 5.2rem;
  padding: 3rem 0rem;
  width: 100%;
  overflow: visible;
`
export const BenefitText = styled(Text)`
  color: ${ThemeStyle.colors.text};
  font-size: 2rem;
  margin-top: 4rem;
`
export const HeaderWrapperDesktop = styled.div`
  ${MobileHidden};
`
export const HeaderWrapperMobile = styled.div`
  ${DesktopHidden};
`
