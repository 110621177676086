import styled, { css, keyframes } from "styled-components"
import {
  AppContainer,
  AppSection,
  mediaUp,
  MobileHidden,
} from "../../../styles/Mixins"
import ThemeStyle from "../../../styles/ThemeStyle"
import { Text } from "../../Typography"

export const Section = styled.section`
  ${AppSection};
  margin: 9rem 0;
  position: relative;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      margin: 20rem 0;
    `
  )}
`

export const Container = styled.div`
  ${AppContainer};
`

export const Content = styled.div`
  margin-top: 5rem;
  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      width: 50%;
      margin-left: auto;
      margin-right: 0;

      p {
        line-height: 4.6rem;
      }
    `
  )}
`

export const Benefits = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 5rem;
  margin-top: 7rem;
`

export const Benefit = styled.div`
  height: 10.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 0;
  margin-bottom: 7rem;
  font-size: 1.7rem;
  line-height: 2.8rem;
  width: 40%;
  font-weight: ${ThemeStyle.weights.light};
  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      height: 7.1rem;
      margin-bottom: 8rem;
    `
  )}
`

export const BenefitSubheader = styled.p`
  display: flex;
  color: #aaa9a9;
  font-size: 1.3rem;
`
export const Note = styled.div`
  font-size: 1.2rem;
  width: 100%;
  margin: 3rem 0rem;
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const CircleConatiner = styled.div`
  ${MobileHidden};
  width: 55rem;
  height: 55rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-30%, -10%);
  svg {
    animation: ${rotate} 24s linear infinite;
    width: 100%;
    height: 100%;
  }
`
export const Motto = styled(Text)`
  font-size: 1.8rem;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 2.4rem;
    `
  )}
`
