import React, { FunctionComponent } from "react"

type Props = {}

const Microsoft: FunctionComponent<Props> = props => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.016 0.000488281H0V18.0164H18.016V0.000488281Z"
        fill="#F25022"
        style={{ fill: "#F25022", stroke: "none" }}
      />
      <path
        d="M37.9075 0H19.8918V18.0157H37.9075V0Z"
        fill="#7FBA00"
        style={{ fill: "#7FBA00", stroke: "none" }}
      />
      <path
        d="M18.0157 19.8989H0V37.9146H18.0157V19.8989Z"
        fill="#00A4EF"
        style={{ fill: "#00A4EF", stroke: "none" }}
      />
      <path
        d="M37.9075 19.8989H19.8918V37.9146H37.9075V19.8989Z"
        fill="#FFB900"
        style={{ fill: "#FFB900", stroke: "none" }}
      />
    </svg>
  )
}

export default Microsoft
