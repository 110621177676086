import React from "react"
import lottie, { AnimationItem, AnimationSegment } from "lottie-web"

export type LottieProps = {
  loop?: boolean | number
  autoplay?: boolean
  initialSegment?: AnimationSegment
  name?: string
  animationData: any
}

type LottieState = {
  animation: AnimationItem | undefined
  lottieRef: (element: HTMLElement | null) => void
  isInitialized: boolean
}

const useLottie: (props: LottieProps) => LottieState = props => {
  const [element, setElement] = React.useState<HTMLElement | null>()
  const [state, setState] = React.useState<LottieState>({
    animation: undefined,
    lottieRef: (element: HTMLElement | null) => {
      setElement(element)
    },
    isInitialized: false,
  })

  React.useEffect(() => {
    if (!element) {
      return
    }
    const a = lottie.loadAnimation({
      ...props,
      container: element,
      renderer: "svg",
    })
    setState(prev => ({
      ...prev,
      animation: a,
      isInitialized: true,
    }))
  }, [element])

  return state
}

export default useLottie
