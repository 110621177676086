import styled, { css } from "styled-components"
import { AppContainer, AppSection, mediaUp } from "../../../styles/Mixins"
import ThemeStyle from "../../../styles/ThemeStyle"
import { motion } from "framer-motion"

export const Section = styled.section`
  ${AppSection};
  background-color: #fcfcfc;
  padding: 2rem 0 8rem;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      padding: 6rem 0 0;
    `
  )}
`

export const Container = styled.div`
  ${AppContainer};
  display: flex;
  flex-direction: column-reverse;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      flex-direction: column;
      height: unset;
    `
  )}
`

export const MainWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  margin-top: 0;
  flex-direction: column-reverse;
  overflow-x: hidden;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      margin-top: 3rem;
      flex-direction: row;
      align-items: flex-start;
      overflow-y: hidden;
      height: 115rem;
    `
  )}

  ${mediaUp(
    ThemeStyle.breakpoints.md,
    css`
      height: 67rem;
    `
  )}
  ${mediaUp(
    ThemeStyle.breakpoints.lg,
    css`
      height: 67rem;
      justify-content: space-between;
    `
  )}

  ${mediaUp(
    ThemeStyle.breakpoints.xxl,
    css`
      margin-top: 5rem;
    `
  )};
`

export const Box = styled.div`
  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      width: 50%;
    `
  )}

  ${mediaUp(
    ThemeStyle.breakpoints.xxl,
    css`
      width: 50%;
    `
  )}
`

export const ImageBox = styled(motion.div)`
  width: 100%;
  display: none;
  margin-top: 0;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      display: flex;
      width: 50%;
      margin-left: 3rem;
      margin-top: 6rem;
      height: 50%;
    `
  )}

  ${mediaUp(
    ThemeStyle.breakpoints.md,
    css`
      margin-left: 5rem;
      height: 70%;
    `
  )}

  ${mediaUp(
    ThemeStyle.breakpoints.xl,
    css`
      margin-left: 5rem;
      margin-right: 5rem;
      width: 50%;
      margin-top: 7rem;
      height: 70%;
    `
  )}

  ${mediaUp(
    ThemeStyle.breakpoints.xl,
    css`
      margin-left: 5rem;
      margin-right: 5rem;
      width: 50%;
      margin-top: 7rem;
      height: 70%;
    `
  )}
`

export const TextBox = styled(motion.div)`
  width: 100%;
  height: 47rem;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      width: 100%;
      height: 30rem;
      margin-top: 0;

      h2 {
        font-size: 4rem;
        padding-top: 18rem;
      }
    `
  )}

  ${mediaUp(
    ThemeStyle.breakpoints.md,
    css`
      h2 {
        padding-top: 2rem;
      }
    `
  )}
`

export const Subheader = styled.div`
  padding: 6rem 0 2.4rem 0;
  color: ${ThemeStyle.colors.header};
  font-weight: 500;
  font-size: 2rem;
  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      margin-top: 5rem;
      font-size: 1.8rem;
      padding: 3rem 0;
    `
  )}
`

export const Text = styled(motion.p)`
  font-size: 1.8rem;
  line-height: 3.6rem;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      padding-top: 3rem;
    `
  )}
`
