import React, { FunctionComponent } from "react"
import animationData from "./animation-data.json"
import * as Styled from "./Atnimation.styled"
import { AnimatePresence } from "framer-motion"
import useLottie from "../../../../Lottie/hooks/useLottie"
import useScrollTo from "../../../../../hooks/useScrollTo"

type Props = {}

const textAnimation = {
  exit: { opacity: 0, letterSpacing: "20px" },
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 1 },
}

const Animation: FunctionComponent<Props> = props => {
  const scrollToOffer = useScrollTo("oferta")
  const [showButton, setShowButton] = React.useState(false)
  const [frame, setFrame] = React.useState<number>(0)
  const { animation, lottieRef } = useLottie({
    animationData: animationData,
    autoplay: false,
  })
  const [slide, setSlide] = React.useState(1)
  const firstSlide = React.useCallback(() => {
    if (!animation) {
      throw Error("Animation should be defined at this point")
    }
    animation.play()
    console.log("go to first slide")
    setSlide(1)
    setTimeout(() => {
      secondSlide()
    }, 4000)
  }, [animation])
  const fourthSlide = React.useCallback(() => {
    if (!animation) {
      throw Error("Animation should be defined at this point")
    }
    animation.play()
    console.log("go to fourth slide")
    setSlide(4)
    setShowButton(true)
    setTimeout(() => {
      firstSlide()
    }, 4000)
  }, [animation])
  const thirdSlide = React.useCallback(() => {
    if (!animation) {
      throw Error("Animation should be defined at this point")
    }
    animation.play()
    console.log("go to third slide")
    setSlide(3)
    setTimeout(() => {
      fourthSlide()
    }, 4000)
  }, [animation, fourthSlide])
  const secondSlide = React.useCallback(() => {
    if (!animation) {
      throw Error("Animation should be defined at this point")
    }
    animation.play()
    setSlide(2)
    console.log("go to second slide")
    setTimeout(() => {
      thirdSlide()
    }, 4000)
  }, [animation, thirdSlide])

  React.useEffect(() => {
    if (!animation) {
      return
    }
    animation.setSpeed(0.8)
    animation.goToAndPlay(200, true)
    animation.addEventListener("enterFrame", a => {
      setFrame(Math.floor(a.currentTime))
    })

    setTimeout(() => {
      secondSlide()
    }, 4000)
  }, [animation])

  React.useEffect(() => {
    if (!animation) {
      return
    }
    const segmentFinals = [55, 95, 145, 195]
    if (frame === 1) {
      animation.goToAndPlay(45, true)
    }
    if (segmentFinals.indexOf(frame) >= 0) {
      animation.pause()
      animation.goToAndStop(frame + 1, true)
    }
  }, [animation, frame])

  return (
    <Styled.Container>
      <Styled.LottieContainer>
        <div
          ref={lottieRef}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </Styled.LottieContainer>
      <AnimatePresence exitBeforeEnter>
        {slide === 1 && (
          <Styled.Text key={1} {...textAnimation}>
            Elas
            <Styled.MobileBr />
            tycz
            <Styled.MobileBr />
            ność
          </Styled.Text>
        )}
        {slide === 2 && (
          <Styled.Text key={2} {...textAnimation}>
            Stan
            <Styled.MobileBr />
            dary
            <Styled.MobileBr />
            zacja
          </Styled.Text>
        )}
        {slide === 3 && (
          <Styled.Text key={3} {...textAnimation}>
            Bezp
            <Styled.MobileBr />
            iecze
            <Styled.MobileBr />
            ństwo
          </Styled.Text>
        )}
        {slide === 4 && (
          <Styled.Text key={4} {...textAnimation}>
            Trans
            <Styled.MobileBr />
            paren
            <Styled.MobileBr />
            tność
          </Styled.Text>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showButton && (
          <Styled.ButtonContainer
            {...textAnimation}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            <Styled.HeaderButton onClick={scrollToOffer}>
              Dowiedz się więcej
            </Styled.HeaderButton>
          </Styled.ButtonContainer>
        )}
      </AnimatePresence>
    </Styled.Container>
  )
}

export default Animation
