import styled, { css } from "styled-components"
import { AppContainer, AppSection, mediaUp } from "../../../styles/Mixins"
import ThemeStyle from "../../../styles/ThemeStyle"
import themeStyle from "../../../styles/ThemeStyle"

export const Section = styled.section`
  ${AppSection};
  margin-top: 5rem;
  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      margin-top: 15rem;
      display: flex;
      justify-content: center;
    `
  )}
`
export const Container = styled.div`
  ${AppContainer};
  text-align: center;
`

export const Columns = styled.div`
  margin-top: 6rem;
  padding: 0 5rem;
  display: flex;
  flex-direction: column;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      justify-content: center;
      padding: 0 0;
    `
  )}

  ${mediaUp(
    ThemeStyle.breakpoints.lg,
    css`
      flex-direction: row;
    `
  )}
`

export const Column = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  ${mediaUp(
    ThemeStyle.breakpoints.md,
    css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    `
  )}

  ${mediaUp(
    ThemeStyle.breakpoints.lg,
    css`
      justify-content: center;
      margin: 0rem 1rem;

      &:nth-child(2) {
        margin-top: 25rem;
      }
    `
  )}
`
