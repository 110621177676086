import React, { FunctionComponent } from "react"

type Props = {}

const Umbrella: FunctionComponent<Props> = props => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 31.6668C30 32.9929 29.4732 34.2647 28.5355 35.2024C27.5978 36.14 26.326 36.6668 25 36.6668C23.6739 36.6668 22.4021 36.14 21.4644 35.2024C20.5267 34.2647 20 32.9929 20 31.6668V20.0002M38.3333 20.0002C37.8979 15.4395 35.7783 11.2047 32.3883 8.12293C28.9984 5.04112 24.5814 3.3335 20 3.3335C15.4185 3.3335 11.0016 5.04112 7.61157 8.12293C4.22158 11.2047 2.10197 15.4395 1.66663 20.0002H38.3333Z"
        style={{ fill: "none" }}
        stroke="#363636"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Umbrella
