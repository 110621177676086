import styled, { css } from "styled-components"
import { motion } from "framer-motion"
import ThemeStyle from "../../styles/ThemeStyle"
import { Transition } from "../../styles/Mixins"

type SwitchProps = {
  checked: boolean
}

export const Wrapper = styled.div`
  /* cursor: pointer; */
`

export const Background = styled(motion.div)`
  width: 8.8rem;
  height: 4.4rem;
  border-radius: 2.2rem;
  padding: 10px;
  display: flex;
  background-color: ${ThemeStyle.backgrounds.dark};

  &.off {
    justify-content: flex-start;
  }
  &.on {
    justify-content: flex-end;
    background-color: ${ThemeStyle.backgrounds.light};
  }
`

export const Dot = styled(motion.div)`
  border-radius: 50%;
  width: 2.4rem;
  height: 2.4rem;
  background-color: ${ThemeStyle.colors.main};
`
