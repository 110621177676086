import styled, { css } from "styled-components"
import ThemeStyle from "../../../../styles/ThemeStyle"
import { mediaDown, mediaUp, Transition } from "../../../../styles/Mixins"

type WrapperType = {
  active: number
}

export const Wrapper = styled.div<WrapperType>`
  height: 6rem;
  width: 82rem;
  display: flex;
  border-radius: 3rem;
  background-color: #f3f3f3;
  margin: auto;
  ${Transition("all", 0.2)}
  position: absolute;
  bottom: 7rem;
  z-index: 10;
  -webkit-box-shadow: 0px 8px 24px 0px rgba(66, 68, 90, 0.1);
  -moz-box-shadow: 0px 8px 24px 0px rgba(66, 68, 90, 0.1);
  box-shadow: 0px 8px 24px 0px rgba(66, 68, 90, 0.1);

  ${props => css`
    ${mediaDown(
      ThemeStyle.breakpoints.md,
      css`
        transform: translateX(-${props.active * 20}%);
      `
    )}
  `}

  ${mediaUp(
    ThemeStyle.breakpoints.md,
    css`
      position: static;
      box-shadow: none;
    `
  )}
`

type ButtonType = {
  active: boolean
}

export const Button = styled.button<ButtonType>`
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3rem;
  color: black;
  ${Transition("all", 0.2)};
  cursor: none;
  font-family: ${ThemeStyle.fonts.second};
  margin: 0rem 0.3rem;

  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }

  ${props =>
    props.active &&
    css`
      background-color: ${ThemeStyle.colors.main};
    `}

  &:active {
    background-color: ${ThemeStyle.colors.main};
  }
  ${mediaUp(
    ThemeStyle.breakpoints.md,
    css`
      &:hover {
        background-color: ${ThemeStyle.colors.main};
      }
    `
  )};
`
