import React, { FunctionComponent } from "react"
import * as Styled from "./HeaderSwitch.styled"
import { motion } from "framer-motion"
import { Cursor } from "../../../CustomCursor/CustomCursor.styled"
import CursorContext from "../../../CustomCursor/context/CursorContext"
type Props = {
  onClick: () => void
}

type State = {
  isOn: boolean
}

const HeaderSwitch: FunctionComponent<Props> = ({ onClick }) => {
  const { events, setCursorState } = React.useContext(CursorContext)
  const [state, setState] = React.useState<State>({
    isOn: false,
  })
  const handleClick = React.useCallback(() => {
    setState(s => ({
      ...s,
      isOn: true,
    }))
    setCursorState("normal")
    onClick()
  }, [onClick])
  return (
    <Styled.Wrapper>
      <Styled.Svg
        onClick={handleClick}
        {...events}
        width="156"
        height="66"
        viewBox="0 0 162 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={"background"}
          d="M0 36C0 16.1177 16.1177 0 36 0H126C145.882 0 162 16.1177 162 36V36C162 55.8822 145.882 72 126 72H36C16.1177 72 0 55.8822 0 36V36Z"
          fill="#E5E1D5"
        />
        <motion.rect
          className={"handler"}
          y="16"
          x="16"
          width="40"
          height="40"
          rx="20"
          fill="#363636"
          animate={{ x: state.isOn ? 91 : 0 }}
          transition={{ duration: 0.5 }}
        />
        <motion.path
          animate={{ opacity: state.isOn ? 0 : 1 }}
          className={"letter"}
          d="M71.8934 28.9001L74.7734 43.0001H78.7734L81.4534 29.4401H81.9734L84.6534 43.0001H88.6534L91.5334 28.9001H89.6734L86.9134 42.4601H86.3934L83.7334 28.9001H79.6934L77.0334 42.4601H76.5134L73.7534 28.9001H71.8934Z"
          fill="#363636"
        />
        <motion.path
          animate={{ opacity: state.isOn ? 0 : 1 }}
          className={"letter"}
          d="M97.6923 41.3201V36.2201L100.372 35.5001L99.9923 34.0201L97.6923 34.6401V29.5401C97.6923 28.5801 97.2323 28.0401 96.2523 28.1201L93.6323 28.3001V29.9801L95.7523 29.8001V35.1801L93.0723 35.9001L93.4523 37.3801L95.7523 36.7601V41.3201H92.9723V43.0001H100.492V41.3201H97.6923Z"
          fill="#363636"
        />
        <motion.path
          animate={{ opacity: state.isOn ? 0 : 1 }}
          className={"letter"}
          d="M111.479 45.3201L112.659 43.0001H112.679V41.3201H111.219V37.3601C111.219 34.5801 109.399 32.8401 106.619 32.8401C103.799 32.8401 102.119 34.6001 102.119 36.7601H103.999C103.999 35.0801 105.219 34.4401 106.619 34.4401C108.279 34.4401 109.199 35.2601 109.279 36.8801L105.459 37.2801C103.019 37.5601 102.059 38.6401 102.059 40.2401C102.059 41.2801 102.499 43.2001 105.439 43.2001C107.919 43.2001 108.979 41.7801 109.359 40.2401H109.879L109.579 41.3001V41.7601C109.579 42.5601 110.039 43.0001 110.819 43.0001H111.079L109.879 45.1801C109.379 46.0201 109.979 46.9401 111.099 46.8601L114.999 46.5001V44.9601L111.479 45.3201ZM105.719 41.5201C104.699 41.5201 103.999 41.0001 103.999 39.9601C103.999 39.0601 104.599 38.7401 105.799 38.6001L109.279 38.2401C109.279 40.1201 107.879 41.5201 105.719 41.5201Z"
          fill="#363636"
        />
        <motion.path
          animate={{ opacity: state.isOn ? 0 : 1 }}
          className={"letter"}
          d="M113.719 38.0001C113.719 40.9801 115.659 43.2001 118.879 43.2001C120.959 43.2001 123.439 42.0401 123.659 39.1601H121.699C121.459 40.8601 120.279 41.5201 118.879 41.5201C116.679 41.5201 115.659 39.9601 115.659 38.0001C115.659 36.0601 116.679 34.5201 118.879 34.5201C120.279 34.5201 121.459 35.1801 121.699 36.8801H123.659C123.439 34.0001 120.959 32.8401 118.879 32.8401C115.659 32.8401 113.719 35.0401 113.719 38.0001Z"
          fill="#363636"
        />
        <motion.path
          animate={{ opacity: state.isOn ? 0 : 1 }}
          className={"letter"}
          d="M125.438 43.0001H134.698V41.3201H127.238L125.958 41.6601V41.1201L134.698 36.5201V33.0401H125.438V34.7201H132.898L134.178 34.3801V34.9201L125.438 39.5201V43.0001Z"
          fill="#363636"
        />
      </Styled.Svg>
    </Styled.Wrapper>
  )
}

export default HeaderSwitch
