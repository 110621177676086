import React, { FunctionComponent } from "react"
import * as Styled from "./WhySection.styled"
import { Header, Underlined } from "../../Typography"
import axios, { AxiosResponse } from "axios"

type Props = {}

const benefitsVariants = {
  hidden: {
    opacity: 0,
    transition: {
      staggerChildren: 0.15,
    },
  },

  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
      when: "beforeChildren",
    },
  },
}

const benefitVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  hidden: {
    y: 50,
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
}

type StateType = {
  averageResponseTime: string
  resolvedRequests: string
  globalSubscribtionCount: string
  CSAT: string
}
type ResponseType = {
  averageResponseTime: number
  resolvedRequests: number
  globalSubscribtionCount: number
  CSAT: number
}

const WhySection: FunctionComponent<Props> = props => {
  const [state, setState] = React.useState<StateType | undefined>()

  const update = React.useCallback(() => {
    axios
      .get("https://serioitstats.blob.core.windows.net/data/stats.json")
      .then((response: AxiosResponse<ResponseType>) => {
        setState({
          averageResponseTime: new Intl.NumberFormat("pl", {
            maximumFractionDigits: 1,
          }).format(response.data.averageResponseTime / 60),
          resolvedRequests: new Intl.NumberFormat("pl").format(
            response.data.resolvedRequests
          ),
          globalSubscribtionCount: new Intl.NumberFormat("pl").format(
            response.data.globalSubscribtionCount
          ),
          CSAT: new Intl.NumberFormat("pl").format(response.data.CSAT),
        })
        setTimeout(update, 20000)
      })
  }, [])

  React.useEffect(() => {
    update()
  }, [])

  return (
    <Styled.Section id={"o-nas"}>
      <Styled.Container>
        <Styled.Subheader>Dlaczego SerioIT?</Styled.Subheader>
        <Header>
          <Styled.HeaderWrapperDesktop>
            Nie znamy się na&nbsp;wszystkim,{" "}
            <Underlined>ale to, na&nbsp;czym się&nbsp;znamy,</Underlined>{" "}
            robimy&nbsp;najlepiej.
          </Styled.HeaderWrapperDesktop>
          <Styled.HeaderWrapperMobile>
            Nie znamy się na&nbsp;wszystkim, ale to, na&nbsp;czym{" "}
            <Underlined>się&nbsp;znamy,</Underlined> robimy&nbsp;najlepiej.
          </Styled.HeaderWrapperMobile>
        </Header>
        <Styled.TextBox>
          <Styled.WhyText>
            Nie ściemniamy, że ogarniemy wszystko i&nbsp;znamy się na wszystkim.
            Specjalizujemy się w wysoko wystandaryzowanych usługach
            i&nbsp;wielokrotnie przetestowanych rozwiązaniach IT.
          </Styled.WhyText>
          <Styled.WhyText>
            Z nami Ty i&nbsp;Twoja firma otrzymujecie najwyższą jakość obsługi i
            &nbsp;święty spokój. Dajemy Ci pewność, że wszystko działa, jak
            należy.
          </Styled.WhyText>
          <Styled.WhyText>Serio, nic więcej nie potrzebujesz.</Styled.WhyText>
        </Styled.TextBox>
        <Styled.Benefits
          variants={benefitsVariants}
          initial="hidden"
          whileInView="visible"
        >
          <Styled.Benefit variants={benefitVariants}>
            <Styled.BenefitNumber>
              {state ? state.averageResponseTime : 0}
            </Styled.BenefitNumber>
            <Styled.BenefitText>
              minut - średni czas reakcji dla zgłoszonych problemów
            </Styled.BenefitText>
          </Styled.Benefit>
          <Styled.Benefit variants={benefitVariants}>
            <Styled.BenefitNumber>
              {state ? state.resolvedRequests : 0}
            </Styled.BenefitNumber>
            <Styled.BenefitText>rozwiązanych zgłoszeń</Styled.BenefitText>
          </Styled.Benefit>
          <Styled.Benefit variants={benefitVariants}>
            <Styled.BenefitNumber>
              {state ? state.globalSubscribtionCount : 0}
            </Styled.BenefitNumber>
            <Styled.BenefitText>obsługiwanych użytkowników</Styled.BenefitText>
          </Styled.Benefit>
          <Styled.Benefit variants={benefitVariants}>
            <Styled.BenefitNumber>
              {state ? state.CSAT : 0}
            </Styled.BenefitNumber>
            <Styled.BenefitText>wynik badań satysfakcji</Styled.BenefitText>
          </Styled.Benefit>
        </Styled.Benefits>
        *Dane pobierane live i&nbsp;aktualizowane co 20 sekund
      </Styled.Container>
    </Styled.Section>
  )
}

export default WhySection
