import styled, { css } from "styled-components"
import { Transition } from "../../../../styles/Mixins"

type SvgProps = {}

export const Wrapper = styled.div`
  ${Transition()};
  border-radius: 4rem;
  &:hover {
    box-shadow: 0px 0px 25px 0px #ffffff40;
  }
`

export const Svg = styled.svg<SvgProps>`
  border-radius: 4rem;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  ${Transition()};

  &:hover {
    box-shadow: 0px 0px 25px 0px #ffffff40;
  }
`
