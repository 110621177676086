import React, { FunctionComponent } from "react"
import Icon from "./Icon.styled"
import { StaticImage } from "gatsby-plugin-image"

type Props = {}

const Elastic: FunctionComponent<Props> = props => {
  return (
    <Icon>
      <StaticImage
        src="../../../../../images/BenefitsSection/elastycznosc.png"
        alt="ikona elastycznosc"
        placeholder="none"
        quality={100}
        objectFit={"contain"}
        width={1200}
      />
    </Icon>
  )
}

export default Elastic
