import React, { FunctionComponent } from "react"
import * as Styled from "./Control.styled"
import CursorContext from "../../../CustomCursor/context/CursorContext"

type Props = {
  onChange: (index: number) => void
  active: number
}

const Control: FunctionComponent<Props> = ({ active, ...props }) => {
  const { events } = React.useContext(CursorContext)
  const handleChange = React.useCallback(
    (index: number) => {
      props.onChange(index)
    },
    [props.onChange]
  )

  return (
    <Styled.Wrapper active={active}>
      <Styled.Button
        active={active === 0}
        type="button"
        onClick={() => handleChange(0)}
        {...events}
      >
        Elastyczność
      </Styled.Button>
      <Styled.Button
        active={active === 1}
        type="button"
        onClick={() => handleChange(1)}
        {...events}
      >
        Standaryzacja
      </Styled.Button>
      <Styled.Button
        active={active === 2}
        type="button"
        onClick={() => handleChange(2)}
        {...events}
      >
        Bezpieczeństwo
      </Styled.Button>
      <Styled.Button
        active={active === 3}
        type="button"
        onClick={() => handleChange(3)}
        {...events}
      >
        Transparentność
      </Styled.Button>
    </Styled.Wrapper>
  )
}

export default Control
