import React, { CSSProperties } from "react"
import StyledNavigation from "./Navigation.styled"
import Menu from "./Menu/Menu"
import MobileMenu from "./Menu/MobileMenu"
import useScroll from "../../../hooks/useScroll"

type Props = {
  animation?: boolean
  disableAnimation: () => void
}

const Navigation: React.FunctionComponent<Props> = ({
  animation = false,
  disableAnimation,
  ...props
}) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false)
  const { top, directionVertical } = useScroll()
  const [lastDirection, setLastDirection] = React.useState<
    undefined | "down" | "up"
  >()
  const [lastTop, setLastTop] = React.useState(0)
  const [show, setShow] = React.useState(true)

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setLastTop(top)
      setLastDirection(directionVertical)
    }, 200)
    return () => {
      clearTimeout(timeout)
    }
  }, [top])

  React.useEffect(() => {
    setLastDirection(undefined)
  }, [directionVertical])

  React.useEffect(() => {
    if (directionVertical === lastDirection) {
      return
    }
    if (Math.abs(top - lastTop) > 200) {
      if (directionVertical === "down") {
        setShow(false)
      } else {
        setShow(true)
      }
    }
  }, [directionVertical, lastDirection, top, lastTop])

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const showBackground: boolean = React.useMemo(() => {
    return top >= 100 ? true : !!animation
  }, [top, animation])

  return (
    <StyledNavigation
      className={showBackground ? "background" : ""}
      id="navigation"
      isShown={show}
    >
      <Menu
        handleMenuToggle={handleMenuToggle}
        isMenuOpen={isMenuOpen}
        disableAnimation={disableAnimation}
      />
      <MobileMenu handleMenuToggle={handleMenuToggle} isMenuOpen={isMenuOpen} />
    </StyledNavigation>
  )
}

export default Navigation
