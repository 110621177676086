import React, { FunctionComponent } from "react"

type Props = {}

const Circles: FunctionComponent<Props> = props => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        style={{ stroke: "none" }}
        d="M12.6304 4.49293C13.1676 5.7991 13.3106 7.0808 12.995 8.40887C12.262 11.5004 9.21042 13.546 6.03903 13.0693C4.50723 12.8339 3.11995 12.0311 2.15257 10.8204C1.18518 9.60958 0.708418 8.07932 0.81692 6.53334C1.17502 1.89605 6.16011 -0.680219 10.1933 1.71185C10.5372 1.91666 10.7819 1.8909 11.1143 1.7389C14.9169 0.00635603 18.865 -0.438049 22.9561 0.436595C23.9505 0.649138 24.4697 1.39497 24.2777 2.32242C24.0858 3.24988 23.3026 3.65951 22.2927 3.52297C21.0407 3.35293 19.7757 3.18419 18.5159 3.17904C16.4858 3.17002 14.5433 3.65436 12.6304 4.49293ZM3.90974 6.97002C3.91213 7.78687 4.23892 8.56931 4.81821 9.14522C5.3975 9.72113 6.18183 10.0433 6.99868 10.0409C7.81553 10.0385 8.59798 9.71176 9.17389 9.13247C9.7498 8.55318 10.072 7.76883 10.0696 6.95198C10.058 5.18595 8.6913 3.88751 6.84926 3.89395C5.24296 3.90039 3.90458 5.3006 3.90974 6.97002Z"
        fill="#363636"
      />
      <path
        style={{ stroke: "none" }}
        d="M4.48937 25.304C5.62035 24.8558 6.723 24.6728 7.88618 24.8326C10.7922 25.2319 13.0825 27.8185 13.1572 30.8263C13.2307 33.7722 11.0962 36.467 8.21982 36.9913C5.63581 37.464 3.48462 36.6667 1.9672 34.5284C0.421434 32.3553 0.429163 30.0341 1.73404 27.727C1.81701 27.6058 1.86458 27.4638 1.8714 27.3171C1.87823 27.1703 1.84405 27.0246 1.7727 26.8962C0.0191126 23.0987 -0.431312 19.151 0.421434 15.053C0.639129 14.0097 1.3708 13.4828 2.31242 13.6709C3.25405 13.8589 3.71777 14.6473 3.4975 15.6894C2.85642 18.6773 3.10542 21.7876 4.21371 24.6355C4.29228 24.8429 4.38245 25.0464 4.48937 25.304ZM10.0168 30.986C10.0168 29.323 8.61785 27.8558 7.04246 27.8713C5.26096 27.888 3.85175 29.2676 3.8569 30.9911C3.8569 31.808 4.18139 32.5914 4.75899 33.169C5.33659 33.7466 6.11998 34.0711 6.93683 34.0711C7.75368 34.0711 8.53707 33.7466 9.11467 33.169C9.69227 32.5914 10.0168 31.808 10.0168 30.9911V30.986Z"
        fill="#363636"
      />
      <path
        style={{ stroke: "none" }}
        d="M33.4414 12.6212C32.1906 13.1365 30.9566 13.2833 29.6736 12.9987C28.19 12.6764 26.8777 11.8176 25.9885 10.5871C25.0993 9.35659 24.6958 7.84105 24.8555 6.33129C25.0151 4.82153 25.7267 3.42392 26.8537 2.40665C27.9807 1.38939 29.4436 0.824121 30.9618 0.819336C33.3216 0.830929 35.1572 1.85886 36.323 3.91086C37.5171 6.0131 37.4179 8.15527 36.1942 10.2227C36.1065 10.3492 36.0567 10.498 36.0505 10.6518C36.0444 10.8055 36.0821 10.9578 36.1594 11.0909C37.9032 14.7818 38.3752 18.9465 37.5016 22.934C37.2981 23.9027 36.5523 24.427 35.6518 24.2544C34.7514 24.0818 34.2207 23.2548 34.4268 22.2848C34.7878 20.6174 34.8748 18.9024 34.6845 17.207C34.5081 15.6236 34.0889 14.0769 33.4414 12.6212ZM34.0211 6.99337C34.034 5.30978 32.6904 3.90185 31.0584 3.88768C29.2962 3.87737 27.9127 5.20673 27.8831 6.94184C27.8721 7.34929 27.9424 7.75485 28.0898 8.13482C28.2373 8.51479 28.4591 8.86155 28.7422 9.15484C29.0252 9.44812 29.3639 9.68204 29.7384 9.84293C30.1129 10.0038 30.5157 10.0885 30.9233 10.0919C31.3308 10.0953 31.735 10.0175 32.1122 9.86287C32.4893 9.7083 32.8319 9.4801 33.1198 9.19162C33.4078 8.90313 33.6353 8.56014 33.7892 8.1827C33.943 7.80527 34.0201 7.40094 34.0159 6.99337H34.0211Z"
        fill="#363636"
      />
      <path
        style={{ stroke: "none" }}
        d="M25.4099 33.4011C24.3794 30.2876 25.7808 27.4834 27.8676 26.1321C28.6395 25.6307 29.5139 25.3084 30.4266 25.1889C31.3392 25.0694 32.2671 25.1557 33.1421 25.4415C34.0171 25.7272 34.817 26.2052 35.4832 26.8404C36.1494 27.4755 36.665 28.2517 36.9922 29.1121C37.3193 29.9725 37.4498 30.8951 37.3739 31.8125C37.2981 32.7298 37.0179 33.6185 36.5539 34.4135C36.0899 35.2084 35.4538 35.8894 34.6923 36.4066C33.9309 36.9237 33.0633 37.2638 32.1533 37.402C31.3506 37.5305 30.5302 37.494 29.7422 37.2946C28.9542 37.0953 28.2151 36.7374 27.5701 36.2427C27.2905 36.0353 27.0935 36.043 26.7972 36.177C23.0049 37.8928 19.0684 38.3037 14.9953 37.4651C14.0227 37.2655 13.4753 36.4758 13.6737 35.5651C13.8643 34.6918 14.6668 34.2164 15.6471 34.4277C18.3894 35.0285 21.2427 34.8822 23.9092 34.0039C24.3201 33.8751 24.7194 33.7025 25.1239 33.5466C25.2115 33.508 25.2939 33.459 25.4099 33.4011ZM31.2168 28.2253C30.4052 28.2347 29.6295 28.5618 29.0562 29.1363C28.4828 29.7109 28.1574 30.4872 28.1497 31.2988C28.1453 31.7014 28.2204 32.1008 28.3708 32.4743C28.5211 32.8478 28.7437 33.1879 29.0258 33.4751C29.3079 33.7623 29.6439 33.991 30.0146 34.1481C30.3852 34.3052 30.7833 34.3875 31.1859 34.3903C32.0034 34.3944 32.7891 34.0736 33.3701 33.4984C33.951 32.9232 34.2797 32.1408 34.2838 31.3233C34.2879 30.5058 33.9671 29.7201 33.3919 29.1391C32.8167 28.5581 32.0343 28.2294 31.2168 28.2253Z"
        fill="#363636"
      />
    </svg>
  )
}

export default Circles
