import styled, { css } from "styled-components"
import { mediaUp } from "../../../styles/Mixins"
import ThemeStyle from "../../../styles/ThemeStyle"
import { Text } from "../../Typography"
import { motion } from "framer-motion"

export const Accordion = styled.div`
  padding-bottom: 3rem;
  margin-bottom: 7rem;
  border-bottom: 1px solid ${ThemeStyle.colors.border};
  width: 100%;
  overflow-y: hidden;
`

export const Question = styled(Text)`
  font-size: 2.2rem;
  line-height: 2.6rem;
  color: ${ThemeStyle.colors.text};
  font-weight: ${ThemeStyle.weights.regular};
  padding-bottom: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  cursor: none;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 2.6rem;
      line-height: 2.5rem;
    `
  )};
`

export const AnimAnswearBox = styled(motion.div)`
  width: 100%;
  overflow-y: hidden;
`

export const Answear = styled(Text)`
  font-size: 1.8rem;
  line-height: 3rem;
`
