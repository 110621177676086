import React, { FunctionComponent } from "react"
import Icon from "./Icon.styled"
import { StaticImage } from "gatsby-plugin-image"

type Props = {}

const Standard: FunctionComponent<Props> = props => {
  return (
    <Icon>
      <StaticImage
        src="../../../../../images/BenefitsSection/standartyzacja.png"
        alt="ikona elastycznosc"
        placeholder="none"
        quality={100}
        objectFit={"contain"}
        height={400}
      />
    </Icon>
  )
}

export default Standard
