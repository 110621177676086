import React, { FunctionComponent } from "react"

type Props = {}

const Sheet: FunctionComponent<Props> = props => {
  return (
    <svg
      width="30"
      height="40"
      viewBox="0 0 30 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        style={{ stroke: "none" }}
        d="M29.995 12.1584V11.7618C29.995 10.2914 29.4938 9.09426 28.4547 8.10221C26.0897 5.84265 23.7715 3.60277 21.4076 1.31525C20.9835 0.889016 20.4802 0.553173 19.9271 0.32736C19.374 0.101548 18.7823 -0.00970452 18.1866 0.00010873H18.1795C16.0633 0.00010873 13.9474 0.00010873 11.8319 0.00010873C9.38226 0.00010873 6.93293 0.00183495 4.48393 0.00528677C4.10509 0.00139833 3.72708 0.0420534 3.35739 0.126445C1.3239 0.618329 0.00823668 2.35494 0.00213623 4.55236C0.00213623 10.0152 0.00213623 15.4781 0.00213623 20.9409V27.0641C0.00213623 27.8987 0.00213623 28.7337 0.00213623 29.5691C0.00213623 31.5169 0.00213598 33.5311 0.00823644 35.5121C0.0163704 37.2611 0.770793 38.5793 2.25117 39.4275C2.58194 39.6016 2.93037 39.7384 3.29028 39.8355C3.44584 39.8841 3.60648 39.9338 3.75899 39.9887L3.7834 39.998H26.226L26.2615 39.9773L26.3053 39.9483L26.3154 39.94C28.113 39.7153 30.0113 38.0367 30.0011 35.3557C29.9727 27.6253 29.9808 19.7666 29.995 12.1584ZM20.4173 3.99524L25.9291 9.30759H25.3536C23.9301 9.30759 21.5459 9.30759 20.7782 9.30759C20.46 9.30759 20.4031 9.25167 20.4031 8.93686C20.399 7.5337 20.4082 4.92723 20.4173 3.99524ZM27.4013 35.3536C27.4013 36.6491 26.7048 37.3636 25.439 37.3636C18.475 37.3636 11.5117 37.3636 4.54901 37.3636C3.30553 37.3636 2.59178 36.6387 2.59178 35.3868C2.59178 25.1293 2.59178 14.8723 2.59178 4.61553C2.59178 3.35942 3.30349 2.63868 4.54799 2.63868H17.8124V8.8302C17.8124 10.748 18.9766 11.9368 20.8494 11.9368H27.4013V18.0062C27.402 23.7866 27.402 29.5691 27.4013 35.3536Z"
        fill="#363636"
      />
      <path
        style={{ stroke: "none" }}
        d="M24.3255 17.334C24.0937 17.8704 23.6585 18.1427 23.0322 18.1427C20.9798 18.1427 18.927 18.1427 16.8738 18.1427H12.4408C10.4582 18.1427 8.47554 18.1427 6.49289 18.1427C6.26078 18.1477 6.03106 18.0942 5.82408 17.9871C5.61711 17.88 5.43928 17.7225 5.30635 17.5287C5.19256 17.3542 5.12361 17.1535 5.10585 16.9449C5.08808 16.7363 5.12207 16.5264 5.20468 16.3347C5.29602 16.0859 5.46129 15.8724 5.67745 15.7239C5.89361 15.5754 6.14988 15.4993 6.41053 15.5062C6.74911 15.5 7.09277 15.5011 7.42728 15.5062H7.86041H11.6529C15.4453 15.5062 19.2381 15.5062 23.0312 15.5062C23.269 15.4989 23.5048 15.5525 23.7171 15.6619C23.9293 15.7713 24.1112 15.9332 24.2462 16.1327C24.3583 16.3097 24.4244 16.5128 24.4382 16.7229C24.4521 16.933 24.4133 17.1433 24.3255 17.334Z"
        fill="#363636"
      />
      <path
        style={{ stroke: "none" }}
        d="M24.3225 23.5431C24.0896 24.0744 23.6535 24.3436 23.0261 24.3436C21.2706 24.3436 19.515 24.3436 17.7594 24.3436H12.4724C10.4768 24.3436 8.4813 24.3436 6.48577 24.3436C6.25357 24.3479 6.02396 24.2934 5.81757 24.1849C5.61117 24.0765 5.43446 23.9175 5.3033 23.7223C5.18932 23.547 5.12086 23.3452 5.10433 23.1356C5.08779 22.9261 5.12372 22.7157 5.20874 22.5242C5.43344 21.9888 5.86556 21.7061 6.45933 21.705H12.7977C16.2085 21.705 19.6194 21.705 23.0302 21.705C23.2674 21.699 23.5024 21.7534 23.7137 21.8633C23.925 21.9733 24.106 22.1353 24.2401 22.3347C24.3539 22.5122 24.4213 22.7165 24.4357 22.928C24.4501 23.1395 24.4111 23.3513 24.3225 23.5431Z"
        fill="#363636"
      />
      <path
        style={{ stroke: "none" }}
        d="M13.2278 29.2346C13.2197 30.003 12.6178 30.5415 11.7708 30.5425C11.1608 30.5425 10.5589 30.5425 9.95289 30.5425H7.55033H6.51935C5.69376 30.5425 5.11117 30.003 5.10405 29.2377C5.09863 29.0695 5.12683 28.9018 5.18696 28.745C5.24708 28.5882 5.33787 28.4454 5.45381 28.3254C5.59832 28.1867 5.76848 28.0787 5.95437 28.0076C6.14027 27.9364 6.3382 27.9037 6.53664 27.9112C8.28679 27.9112 10.0373 27.9112 11.7881 27.9112C11.988 27.9048 12.1871 27.939 12.3738 28.0117C12.5606 28.0844 12.7313 28.1942 12.876 28.3347C12.9908 28.4531 13.0811 28.5938 13.1416 28.7483C13.202 28.9029 13.2313 29.0683 13.2278 29.2346Z"
        fill="#363636"
      />
    </svg>
  )
}

export default Sheet
