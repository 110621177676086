type MenuLinksType = {
  title: string
  address: string
}

const menuLinks: MenuLinksType[] = [
  {
    title: "Oferta",
    address: "#oferta",
  },
  {
    title: "O nas",
    address: "#o-nas",
  },
  {
    title: "Kontakt",
    address: "#formularz",
  },
]

export default menuLinks
