import styled, { css } from "styled-components"
import { motion } from "framer-motion"
import { Transition } from "../../../styles/Mixins"

type Props = {
  isShown: boolean
}

const StyledNavigation = styled.nav<Props>`
  ${Transition()};
  width: 100%;
  height: 9rem;
  position: fixed;
  top: 0;
  z-index: 21;
  color: white;

  &.background {
    box-shadow: inset 0 0 2000px rgba(60, 60, 60, 0.7);
    background: none;
    backdrop-filter: blur(10px);
  }

  ${props =>
    !props.isShown &&
    css`
      top: -100px;
    `}
`

export default StyledNavigation
