import React, { FunctionComponent } from "react"
import * as Styled from "./Switch.styled"

import { Cursor } from "../CustomCursor/CustomCursor.styled"
import CursorContext from "../CustomCursor/context/CursorContext"

type Props = {
  defaultValue?: boolean
  onChange?: (value: boolean) => void
}

const Switch: FunctionComponent<Props> = ({
  defaultValue = false,
  onChange,
  ...props
}) => {
  const [checked, setChecked] = React.useState<boolean>(defaultValue)
  const { events } = React.useContext(CursorContext)

  const handleClick = React.useCallback(() => {
    setChecked(prev => !prev)
  }, [])

  React.useEffect(() => {
    if (onChange) {
      onChange(checked)
    }
  }, [checked, onChange])

  React.useEffect(() => {
    setChecked(defaultValue)
  }, [defaultValue])

  return (
    <Styled.Wrapper onClick={handleClick} {...events}>
      <Styled.Background className={checked ? "on" : "off"}>
        <Styled.Dot
          layout
          transition={spring}
          className={checked ? "on" : "off"}
        />
      </Styled.Background>
    </Styled.Wrapper>
  )
}

const spring = {
  type: "spring",
  stiffness: 900,
  damping: 45,
}

export default Switch
