import * as React from "react"
import MainLayout from "../layouts/MainLayout/MainLayout"
import HeaderSection from "../components/Sections/HeaderSection/HeaderSection"
import OfferSection from "../components/Sections/OfferSection/OfferSection"
import BenefitsSection from "../components/Sections/BenefitsSection/BenefitsSection"
import SubscriptionDetailsSection from "../components/Sections/SubscriptionDetailsSection/SubscriptionDetailsSection"
import SubscriptionContentSection from "../components/Sections/SubscriptionContentSection/SubscriptionContentSection"
import WhySection from "../components/Sections/WhySection/WhySection"
import FormSection from "../components/Sections/FormSection/FormSection"
import FaqSection from "../components/Sections/FaqSection/FaqSection"
import AfterBenefitsSection from "../components/Sections/AfterBenefitsSection/AfterBenefitsSection"
import FaqAfterSection from "../components/Sections/FaqAfterSection/FaqAfterSection"

const IndexPage = () => {
  return (
    <MainLayout>
      <HeaderSection />
      <OfferSection />
      <BenefitsSection />
      <AfterBenefitsSection />
      <SubscriptionContentSection />
      <SubscriptionDetailsSection />
      <FormSection />
      <FaqSection />
      <FaqAfterSection />
      <WhySection />
    </MainLayout>
  )
}

export default IndexPage
