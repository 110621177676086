import styled, { css } from "styled-components"
import { motion } from "framer-motion"
import ThemeStyle from "../../../../../styles/ThemeStyle"
import Button from "../../../../Button"
import { DesktopHidden, mediaUp } from "../../../../../styles/Mixins"

export const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  margin: 0 auto;

  svg * {
    fill: ${ThemeStyle.colors.black};
  }

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      height: 90%;
    `
  )}
`

export const Text = styled(motion.div)`
  position: absolute;
  text-align: center;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -35%);
  color: ${ThemeStyle.colors.main};
  font-size: 10rem;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      top: 30%
      font-size: 6rem;
    `
  )}
  ${mediaUp(
    ThemeStyle.breakpoints.md,
    css`
      font-size: 10rem;
    `
  )}
  ${mediaUp(
    ThemeStyle.breakpoints.lg,
    css`
      font-size: 12rem;
    `
  )}
  ${mediaUp(
    ThemeStyle.breakpoints.xl,
    css`
      font-size: 13rem;
    `
  )}
  ${mediaUp(
    ThemeStyle.breakpoints.xxl,
    css`
      font-size: 15rem;
    `
  )}
`

export const ButtonContainer = styled(motion.div)`
  display: flex;
  margin: auto;
  justify-content: center;
  position: absolute;
  bottom: 5rem;
  width: 90%;
  left: 50%;
  transform: translateX(-50%) translateY(-6rem);

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      bottom: 5rem;
      transform: translateX(-50%) translateY(-5rem);
    `
  )}
  ${mediaUp(
    ThemeStyle.breakpoints.xxl,
    css`
      bottom: 5rem;
      transform: translateX(-50%) translateY(-5rem);
    `
  )}
`

export const HeaderButton = styled(Button)`
  background-color: ${ThemeStyle.colors.black};
  color: ${ThemeStyle.colors.white};
  font-size: 1.6rem;
  border: 1px solid ${ThemeStyle.colors.white};
  padding: 3rem 7rem;
  margin: auto;

  &:active {
    background-color: ${ThemeStyle.colors.main};
    color: ${ThemeStyle.colors.black};
    border: 1px solid ${ThemeStyle.colors.main};
  }

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      padding: 3rem 7rem;

      &:hover {
        background-color: ${ThemeStyle.colors.main};
        color: ${ThemeStyle.colors.black};
        border: 1px solid ${ThemeStyle.colors.main};
      }
    `
  )}
`

export const LottieContainer = styled.div`
  height: 100%;
  width: 100%;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      height: 80%;
      width: 85%;
      margin: 8rem auto;
    `
  )}
`

export const MobileBr = styled.br`
  ${DesktopHidden};
`
