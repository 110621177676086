import styled, { css, keyframes } from "styled-components"
import ThemeStyle from "../../styles/ThemeStyle"
import { mediaDown, mediaUp, Transition } from "../../styles/Mixins"
import { motion } from "framer-motion"

const frontCardKeyframes = keyframes`
  0% {
    transform: rotate(0deg) translate(-3rem, -3rem);
  }
  50% {
    transform: rotate(10deg);
    z-index: 15;


  }
  100% {
    transform: rotate(0deg) translate(-3rem, -3rem);
    z-index: 15;

  }
`

const backCardKeyframes = keyframes`
  0% {
    transform:  rotate(0deg);
  }
  50% {
    transform: rotate(-10deg);
    z-index: 10;


  }
  100% {
    transform: rotate(0deg) ;
    z-index: 10;

  }
`

type CardProps = {
  isShown: boolean
}

export const Container = styled(motion.div)`
  position: relative;
  display: flex;
  justify-content: center;

  .switch-container {
    visibility: hidden;
  }

  ${Transition()};

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`

    .switch-container {
      visibility: hidden;
      opacity: 0;
    }

    &:hover {
      .switch-container {
        visibility: unset;
        opacity: 1;
        ${Transition()};
      )}
    }
  `
  )}
`

export const Card = styled(motion.div)<CardProps>`
  text-align: left;
  width: 30rem;
  height: 30rem;
  max-width: 28rem;
  min-width: 28rem;
  font-size: 1.6rem;
  border-radius: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4rem 5rem 4rem 5rem;
  z-index: 10;
  margin-bottom: 5rem;
  line-height: 2.2rem;

  ${mediaUp(
    ThemeStyle.breakpoints.md,
    css`
      width: 30rem;
      height: 30rem;
      min-width: 14rem;
      padding: 6rem 5rem 4rem 4rem;
      margin-right: 2rem;
    `
  )}

  ${mediaUp(
    ThemeStyle.breakpoints.lg,
    css`
      width: 32rem;
      height: 32rem;
      margin-right: 0;
      min-height: 32rem;
      min-width: 32rem;
      margin-bottom: 12rem;
      padding: 8rem 5rem 7rem 4rem;
    `
  )}


  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      ${(props: CardProps) =>
        props.isShown &&
        css`
          z-index: 15;
          transform: translate(-3rem, -3rem);

          &.animate {
            animation: ${frontCardKeyframes} 0.3s ease-in-out;
          }
        `}

      ${(props: CardProps) =>
        !props.isShown &&
        css`
          z-index: 10;
          transform: rotate(3deg);

          &.animate {
            animation: ${backCardKeyframes} 0.3s ease-in-out;
          }
        `}
    `
  )}

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      transform: rotate(0) translate(0, 0);
      visibility: hidden;
      opacity: 0;
      ${Transition()}
      ${(props: CardProps) =>
        props.isShown &&
        css`
          visibility: visible;
          opacity: 1;
        `}
    `
  )}
`

export const FrontCard = styled(Card)`
  background-color: ${ThemeStyle.backgrounds.light};
  position: relative;

  svg * {
    ${Transition()};
    fill: ${ThemeStyle.colors.text};
    stroke: ${ThemeStyle.colors.text};
  }
`

export const BackCard = styled(Card)`
  position: absolute;
  background-color: ${ThemeStyle.backgrounds.dark};
  color: ${ThemeStyle.colors.textInverted};

  svg * {
    fill: ${ThemeStyle.colors.textInverted};
    stroke: ${ThemeStyle.colors.textInverted};
  }
`

export const Text = styled.div`
  ${Transition("all", 0.35)}
  transition-timing-function: ease-in-out;
  font-size: 1.3rem;
  line-height: 2rem;
  position: absolute;
  padding-right: 3rem;
`

export const Icon = styled.div``

export const SwitchContainer = styled.div`
  position: absolute;
  z-index: 20;
  right: 0;
  transform: translateX(50%);
  padding-top: 7rem;
  display: none;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      display: block;
    `
  )}

  ${mediaUp(
    ThemeStyle.breakpoints.md,
    css`
      display: block;
    `
  )}
`
