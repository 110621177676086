import React, { FunctionComponent } from "react"

type BurgerMenuIconProps = {
  open?: boolean
  dark?: boolean
}

const BurgerMenuIcon: FunctionComponent<BurgerMenuIconProps> = ({
  open,
  dark,
}) => {
  return (
    <svg
      width="34"
      height="22"
      xmlns="http://www.w3.org/2000/svg"

      //optional rotate:
      // transform={open ? "rotate(180)" : "rotate(0)"}
      // style={{ transformOrigin: "center", transition: "0.4s" }}
    >
      <rect
        width="34"
        height="2"
        y={open ? "10" : "0"}
        fill={dark ? "#363636" : "#E5E1D5"}
        transform={open ? "rotate(45)" : "rotate(0)"}
        style={{ transformOrigin: "center", transition: "0.2s" }}
      />
      <rect
        x={open ? "15" : "5"}
        y="10"
        width={open ? "0" : "29"}
        height="2"
        fill={dark ? "#363636" : "#E5E1D5"}
        style={{ transformOrigin: "center", transition: "0.4s" }}
      />
      <rect
        x={open ? "0" : "10"}
        y={open ? "10" : "20"}
        width={open ? "34" : "24"}
        height="2"
        fill={dark ? "#363636" : "#E5E1D5"}
        transform={open ? "rotate(-45)" : "rotate(0)"}
        style={{ transformOrigin: "center", transition: "0.2s" }}
      />
    </svg>
  )
}

export default BurgerMenuIcon
