import React, { FunctionComponent } from "react"
import * as Styled from "./Card.styled"
import Switch from "../Switch/Switch"
import { motion } from "framer-motion"
import { StaggeringChild, StaggeringParent } from "../../styles/Mixins"
import CursorContext from "../CustomCursor/context/CursorContext"

type Props = {}
type CardProps = {
  icon: React.ReactElement
  text: string | React.ReactElement
  hiddenText: string | React.ReactElement
}

const Card: FunctionComponent<CardProps> = ({ children, icon, ...props }) => {
  const [showBack, setActive] = React.useState<boolean>(false)
  const [animate, setAnimate] = React.useState<boolean>(false)

  const toggleState = React.useCallback(() => {
    setAnimate(true)
    setTimeout(() => {
      setAnimate(false)
      setActive(a => !a)
    }, 300)
  }, [])

  const handleSwitch = React.useCallback((active: boolean) => {
    setActive(active)
  }, [])

  const { events } = React.useContext(CursorContext)

  return (
    <Styled.Container
      as={motion.div}
      variants={StaggeringParent}
      initial={"hidden"}
      whileInView={"show"}
    >
      <Styled.SwitchContainer className={"switch-container"}>
        <Switch onChange={handleSwitch} defaultValue={showBack} />
      </Styled.SwitchContainer>
      <Styled.FrontCard
        {...events}
        isShown={!showBack}
        className={animate ? "animate" : undefined}
        onClick={() => toggleState()}
      >
        <Styled.Icon>{icon}</Styled.Icon>
        <motion.div
          animate={{ y: showBack ? 50 : 0, opacity: showBack ? 0 : 1 }}
          transition={{ duration: 0.2 }}
        >
          {props.text}
        </motion.div>
      </Styled.FrontCard>
      <Styled.BackCard
        {...events}
        isShown={showBack}
        className={animate ? "animate" : undefined}
        onClick={() => toggleState()}
      >
        {icon}
        <motion.div
          animate={{ y: !showBack ? 50 : 0, opacity: !showBack ? 0 : 1 }}
          transition={{ duration: 0.2 }}
        >
          {props.hiddenText}
        </motion.div>
      </Styled.BackCard>
    </Styled.Container>
  )
}

export default Card
