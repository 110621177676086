import React, { FunctionComponent } from "react"

type PlusProps = {
  width?: number
  height?: number
  open?: boolean
}

const PlusIcon: FunctionComponent<PlusProps> = ({ width, height, open }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="6"
        width="2"
        transform={open ? "rotate(90)" : "rotate(0)"}
        height="13"
        fill="#363636"
        style={{ transformOrigin: "center", transition: "0.2s" }}
      />
      <rect
        x="13.5"
        y="5.5"
        width="2"
        height="13"
        transform="rotate(90 13.5 5.5)"
        fill="#363636"
      />
    </svg>
  )
}

export default PlusIcon
