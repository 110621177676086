import styled, { css } from "styled-components"
import { AppContainer, AppSection, mediaUp } from "../../../styles/Mixins"
import ThemeStyle from "../../../styles/ThemeStyle"
import { Text, Underlined } from "../../Typography"
import { Subheader as BaseSubheader } from "../../Typography"
import { Header as BaseHeader } from "../../Typography"

export const Section = styled.section`
  ${AppSection};

  background: ${ThemeStyle.backgrounds.light};
`

export const Container = styled.div`
  ${AppContainer};
  padding: 8rem 0rem;
  color: ${ThemeStyle.colors.text};
  display: grid;
  grid-template-columns: 1fr;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      padding: 10rem 0rem;
      grid-template-columns: repeat(2, 1fr);
    `
  )};
`

export const Box = styled.div`
  width: 100%;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      padding: 4rem 0rem;
    `
  )};
`

export const Header = styled(BaseHeader)`
  font-size: 3.6rem;
  line-height: 5rem;
  max-width: 23rem;
  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 6.4rem;
      line-height: 7.2rem;
      max-width: 40rem;
    `
  )};
`
export const Subheader = styled(BaseSubheader)`
  font-size: 2rem;
  line-height: 2.8rem;
  color: ${ThemeStyle.colors.text};

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      margin-bottom: 5rem;
      font-size: 2.6rem;
      line-height: 4.6rem;
    `
  )};
`
