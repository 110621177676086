import styled, { css } from "styled-components"
import { AppContainer, mediaUp } from "../../../../styles/Mixins"
import ThemeStyle from "../../../../styles/ThemeStyle"
import Button from "../../../Button/Button"
import { motion } from "framer-motion"

type MobileMenuType = {
  isMenuOpen: boolean
}

export const Container = styled.div`
  ${AppContainer};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Menu = styled.div<MobileMenuType>`
  position: fixed;
  transform: ${({ isMenuOpen }) =>
    isMenuOpen ? "translateX(0)" : "translateX(100%)"};
  height: 95vh;
  display: block;
  width: 100%;
  padding: 3rem 0rem;
  background-color: ${ThemeStyle.backgrounds.light};
  z-index: -1;
  transition: ${ThemeStyle.transition};
  ${mediaUp(
    ThemeStyle.breakpoints.md,
    css`
      display: none;
    `
  )};
`

export const Links = styled(motion.ul)`
  margin-top: 6rem;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const Link = styled(motion.a)`
  display: block;
  color: ${ThemeStyle.colors.text};
  margin: 2rem 0rem;
  font-size: 3.2rem;
  cursor: pointer;

  &:hover {
    color: ${ThemeStyle.colors.navHover};
  }
  &:focus {
    color: ${ThemeStyle.colors.navHover};
  }
`

export const SubscribeButton = styled(Button)`
  font-size: 1.6rem;
  padding: 3rem 4rem;
  margin-bottom: 8rem;
  width: 100%;
`
export const CustomButton = styled(motion.button)`
  font-size: 1.6rem;
  padding: 3rem 4rem;
  margin-top: 3rem;
  width: 100%;
  font-family: ${ThemeStyle.fonts.second};
  background: ${ThemeStyle.colors.main};
  border-radius: 100px;
  transition: background 0.4s;
  color: black;

  &:active {
    background: ${ThemeStyle.colors.black};
    color: ${ThemeStyle.colors.white};
  }
`
