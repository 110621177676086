import React, { FunctionComponent } from "react"
import { Header } from "../../Typography"
import Underlined from "../../Typography/Underlined"
import * as Styled from "./SubscriptionDetailsSection.styled"
import { motion } from "framer-motion"
import { StaggeringParent, StaggeringChild } from "../../../styles/Mixins"
type Props = {}

const SubscriptionDetailsSection: FunctionComponent<Props> = props => {
  return (
    <Styled.Section>
      <Styled.Container>
        <Header inverted={true}>
          <Underlined color={"#FCFCFC55"}>Jak to działa?</Underlined>
        </Header>
        <Styled.Benefits
          as={motion.div}
          variants={StaggeringParent}
          initial={"hidden"}
          whileInView={"show"}
        >
          <Styled.Benefit as={motion.div} variants={StaggeringChild}>
            <Styled.BenefitNumber>01</Styled.BenefitNumber>
            <Styled.BenefitText>
              Wypełniasz ankietę, żebyśmy mogli poznać Twoje potrzeby
              i&nbsp;Twoje zasoby.
            </Styled.BenefitText>
          </Styled.Benefit>
          <Styled.Benefit as={motion.div} variants={StaggeringChild}>
            <Styled.BenefitNumber>02</Styled.BenefitNumber>
            <Styled.BenefitText>
              Tworzymy dla Ciebie najmniej spersonalizowaną ofertę zawierającą
              wszystko, czego potrzebujesz.
            </Styled.BenefitText>
          </Styled.Benefit>
          <Styled.Benefit as={motion.div} variants={StaggeringChild}>
            <Styled.BenefitNumber>03</Styled.BenefitNumber>
            <Styled.BenefitText>
              My zajmujemy się wszystkim, a&nbsp;Ty masz spokój i&nbsp;pewność,
              że&nbsp;wszystko w&nbsp;Twojej firmie działa, jak trzeba.
            </Styled.BenefitText>
          </Styled.Benefit>
        </Styled.Benefits>
      </Styled.Container>
    </Styled.Section>
  )
}

export default SubscriptionDetailsSection
