import React, { useEffect, useState } from "react"
import * as Styled from "./HeaderSection.styled"
import HeaderSwitch from "./Components/HeaderSwitch"
import { AnimatePresence } from "framer-motion"
import Animation from "./Components/Animation/Animation"
import useScrollTo from "../../../hooks/useScrollTo"
import Navigation from "../Navigation/Navigation"
const variants = {
  visible: {},
  hidden: {
    opacity: 0,
  },
}

const HeaderSection = () => {
  const [showAnimation, setShowAnimation] = React.useState(false)
  const [isApple, setIsApple] = useState<boolean>()

  const enableAnimation = React.useCallback(() => {
    const timeout = setTimeout(() => {
      setShowAnimation(true)
    }, 800)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const disableAnimation = () => {
    setShowAnimation(false)
  }

  const scrollToForm = useScrollTo("oferta")

  useEffect(() => {
    const expression = /(Mac|iPhone|iPod|iPad)/i
    if (navigator) {
      setIsApple(expression.test(navigator.platform))
    }
  }, [])

  return (
    <>
      <Navigation
        animation={showAnimation}
        disableAnimation={disableAnimation}
      />
      <Styled.Section isOn={showAnimation} id="start">
        <AnimatePresence>
          {!showAnimation && (
            <Styled.CircleBig exit={{ scale: 1.2, opacity: 0 }} />
          )}
        </AnimatePresence>
        <Styled.Container>
          <AnimatePresence initial={false} exitBeforeEnter>
            {!showAnimation && (
              <Styled.MottoWrapper
                initial={{ opacity: 1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                key={"motto"}
              >
                <Styled.Line variants={variants}>
                  <Styled.SwitchContainer className={isApple ? "ios" : ""}>
                    <HeaderSwitch onClick={() => enableAnimation()} />
                  </Styled.SwitchContainer>
                  <Styled.HeaderWrapper>obsługę IT</Styled.HeaderWrapper>
                </Styled.Line>
                <Styled.HeaderYellowWrapper>
                  w modelu subskrypcyjnym
                </Styled.HeaderYellowWrapper>
                <Styled.Subheader>
                  Święty spokój dla Twojej firmy. U nas działa.
                </Styled.Subheader>
                <Styled.ButtonContainer exit={variants.hidden}>
                  <Styled.HeaderButton onClick={scrollToForm}>
                    Dowiedz się więcej
                  </Styled.HeaderButton>
                </Styled.ButtonContainer>
              </Styled.MottoWrapper>
            )}
            {showAnimation && (
              <Styled.AnimationContainer
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.5 }}
                key={"animation"}
              >
                <Animation />
              </Styled.AnimationContainer>
            )}
          </AnimatePresence>
        </Styled.Container>
      </Styled.Section>
    </>
  )
}

export default HeaderSection
