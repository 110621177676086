import styled, { css } from "styled-components"
import {
  AppContainer,
  mediaUp,
  AppSection,
  Transition,
  mediaDown,
} from "../../../styles/Mixins"
import ThemeStyle from "../../../styles/ThemeStyle"
import { Header as BaseHeader, Text } from "../../Typography"
import Button from "../../Button/Button"
import { motion } from "framer-motion"

export type AnimationStateProps = {
  isOn?: boolean
}

export const Section = styled.header<AnimationStateProps>`
  ${AppSection};
  width: 100%;
  height: 100vh;
  background-color: ${ThemeStyle.backgrounds.dark};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  ${Transition("all", 1)};
  overflow: hidden;

  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      padding-bottom: 7rem;
    `
  )};

  ${props =>
    props.isOn &&
    css`
      background-color: #e5e1d5;
    `}
`

export const Container = styled.div`
  ${AppContainer};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12rem 0 0;
  height: auto;
  z-index: 10;
  position: relative;
`

export const InnerContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  width: 95vw;
  height: 60vh;
  transform: translateY(-25%);
`

export const AnimationContainer = styled(InnerContainer)`
  height: 100vh;
  width: 95vw;
  transform: translateY(-40%);
`

export const MottoWrapper = styled(InnerContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      margin: 0rem 0rem 8rem;
    `
  )};

  ${mediaUp(
    ThemeStyle.breakpoints.xxl,
    css`
      margin: 0rem 0rem 8rem;
    `
  )};
`

export const Line = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  padding-bottom: 0.6rem;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      width: auto;
      flex-direction: row;
      padding-bottom: 3.4rem;
    `
  )}
`

export const HeaderWrapper = styled.div`
  font-size: 3.5rem;
  color: ${ThemeStyle.colors.headerInverted};
  text-align: center;
  padding-bottom: 0;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 5.2rem;
    `
  )};

  ${mediaUp(
    ThemeStyle.breakpoints.xxl,
    css`
      font-size: 6.2rem;
      line-height: 5.3rem;
    `
  )};
`
export const HeaderYellowWrapper = styled.div`
  padding-bottom: 0.8em;
  font-size: 3.5rem;
  line-height: 4.8rem;
  color: ${ThemeStyle.colors.main};
  text-align: center;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 5.2rem;
    `
  )};

  ${mediaUp(
    ThemeStyle.breakpoints.xxl,
    css`
      font-size: 6.2rem;
      text-align: center;
    `
  )};
`

export const Subheader = styled(Text)`
  color: ${ThemeStyle.colors.textInverted};
  text-align: center;
  font-size: 1.6rem;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 2.4rem;
    `
  )};
`

export const ButtonContainer = styled(motion.div)`
  margin: auto;
`

export const HeaderButton = styled(Button)`
  background-color: ${ThemeStyle.colors.black};
  color: ${ThemeStyle.colors.white};
  font-size: 1.6rem;
  border: 1px solid ${ThemeStyle.colors.white};
  padding: 3rem 7rem;

  &:hover {
    background-color: ${ThemeStyle.colors.main};
    color: ${ThemeStyle.colors.black};
    border: 1px solid ${ThemeStyle.colors.main};
  }
  &:active {
    background-color: ${ThemeStyle.colors.main};
    color: ${ThemeStyle.colors.black};
    border: 1px solid ${ThemeStyle.colors.main};
  }
`
export const SwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 0.4rem;
  margin-bottom: 6rem;

  &.ios {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      margin-bottom: 0;
      padding-bottom: 0;
    `
  )};
`
export const CircleBig = styled(motion.div)`
  display: none;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      display: block;
      position: absolute;
      top: -150px;
      width: 650px;
      height: 650px;
      left: -370px;

      background: rgb(254, 253, 83);
      background: radial-gradient(
        circle,
        rgba(254, 253, 83, 1) 0%,
        rgba(254, 253, 83, 1) 25%,
        rgba(254, 253, 83, 0) 50%
      );

      filter: blur(97px);
    `
  )};

  ${mediaUp(
    ThemeStyle.breakpoints.xxl,
    css`
      width: 950px;
      height: 950px;
      left: -570px;
      top: -200px;
    `
  )};
`
export const CircleSmall = styled(motion.div)`
  display: none;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      display: block;
      position: absolute;
      bottom: 100px;
      right: 150px;
      width: 80px;
      height: 80px;

      background: rgb(254, 253, 83);
      background: radial-gradient(
        circle,
        rgba(254, 253, 83, 1) 0%,
        rgba(254, 253, 83, 1) 75%,
        rgba(254, 253, 83, 0) 50%
      );

      filter: blur(20px);
    `
  )};

  ${mediaUp(
    ThemeStyle.breakpoints.xxl,
    css`
      right: 275px;
      width: 120px;
      height: 120px;
    `
  )};
`
