import React, { FunctionComponent, useState } from "react"
import * as Styled from "./Accordion.styled"
import PlusIcon from "../../../icons/PlusIcon"
import animatePresence, { AnimatePresence } from "framer-motion"
import CursorContext from "../../CustomCursor/context/CursorContext"

interface AccordionProps {
  question: string
  answear: React.ReactElement | string
  key: number
}

const Accordion: FunctionComponent<AccordionProps> = ({
  question,
  answear,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { events } = React.useContext(CursorContext)

  return (
    <Styled.Accordion {...events}>
      <Styled.Question onClick={() => setIsOpen(!isOpen)}>
        {question}

        <PlusIcon open={isOpen} width={17} height={25} />
      </Styled.Question>
      <AnimatePresence>
        {isOpen && (
          <Styled.AnimAnswearBox
            initial={{ opacity: 0, height: 0 }}
            animate={{
              opacity: 1,
              height: "auto",
            }}
            exit={{ opacity: 0, height: 0 }}
          >
            <Styled.Answear>{answear}</Styled.Answear>
          </Styled.AnimAnswearBox>
        )}
      </AnimatePresence>
    </Styled.Accordion>
  )
}

export default Accordion
