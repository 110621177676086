import React, { FunctionComponent } from "react"
import * as Styled from "./AfterBenefitsSection.styled"
import { StaticImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"

type Props = {}

const AfterBenefitsSection: FunctionComponent<Props> = props => {
  return (
    <Styled.Section>
      <Styled.Container>
        <Styled.AnimatedImageDiv
          initial={{
            opacity: 0,
            x: 500,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
          }}
          transition={{
            duration: 1,
            delay: 0.2,
            ease: "easeOut",
          }}
        >
          <Styled.ImageDesktop>
            <StaticImage
              src={"../../../images/AfterBenefitSection/background.jpg"}
              alt={"Kobieta pracująca na komputerze"}
              placeholder={"blurred"}
              quality={100}
              width={2560}
            />
          </Styled.ImageDesktop>
        </Styled.AnimatedImageDiv>

        <Styled.ImageMobile>
          <StaticImage
            src={"../../../images/AfterBenefitSection/background--mobile.png"}
            alt={"Kobieta pracująca na komputerze"}
            placeholder={"blurred"}
          />
        </Styled.ImageMobile>
      </Styled.Container>
      <Styled.SecondBackground />
    </Styled.Section>
  )
}

export default AfterBenefitsSection
