import React, { FunctionComponent } from "react"
import * as Styled from "./BenefitsSection.styled"
import Elastic from "./Icons/Icons-png/Elastic"
import Standard from "./Icons/Icons-png/Standard"
import Security from "./Icons/Icons-png/Security"
import Transparency from "./Icons/Icons-png/Transparency"
import Control from "./Control/Control"
import { AnimatePresence } from "framer-motion"

import { Header, Underlined } from "../../Typography"

type Props = {}

const textAnimation = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -50 },
  transition: {
    duration: 0.2,
  },
}

const imageAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.3 },
}

const BenefitsSection: FunctionComponent<Props> = props => {
  const [active, setActive] = React.useState<number>(0)
  return (
    <Styled.Section>
      <Styled.Container>
        <Control onChange={setActive} active={active} />
        <Styled.MainWrapper>
          <Styled.Box>
            <Styled.Subheader>SerioIT to:</Styled.Subheader>

            <AnimatePresence exitBeforeEnter initial={false}>
              {active === 0 && (
                <Styled.TextBox {...textAnimation} key="first">
                  <Header as={"h2"}>
                    <Underlined>Elastyczność</Underlined>
                  </Header>
                  <Styled.Text>
                    Dopasuj subskrypcję do&nbsp;potrzeb swojej firmy
                    i&nbsp;rozwijaj ją&nbsp;bez obaw o&nbsp;kwotę
                    na&nbsp;fakturze. Miesięczna opłata zależy od&nbsp;liczby
                    pracowników. Możesz na&nbsp;bieżąco zarządzać zmieniającym
                    się&nbsp;zapotrzebowaniem na&nbsp;usługę, a&nbsp;wraz
                    ze&nbsp;wzrostem zatrudnienia otrzymasz progresywny rabat.
                  </Styled.Text>
                </Styled.TextBox>
              )}

              {active === 1 && (
                <Styled.TextBox {...textAnimation} key="second">
                  <Header as={"h2"}>
                    <Underlined>Standaryzacja</Underlined>
                  </Header>
                  <Styled.Text>
                    Solidnie przetestowane standardy pozwalają nam
                    na&nbsp;skuteczne wdrożenie rozwiązań IT w&nbsp;Twojej
                    firmie. Nie wymyślamy koła na&nbsp;nowo - korzystamy
                    z&nbsp;automatyzacji, która na&nbsp;serio sprawia,
                    że&nbsp;cały proces jest szybki i&nbsp;bezbolesny.
                  </Styled.Text>
                </Styled.TextBox>
              )}

              {active === 2 && (
                <Styled.TextBox {...textAnimation} key="third">
                  <Header as={"h2"}>
                    <Underlined>Bezpieczeństwo</Underlined>
                  </Header>
                  <Styled.Text>
                    Pakiet IT-Node na&nbsp;start pozwoli Ci&nbsp;spać spokojnie.
                    Zapewniamy Twojej firmie sprawdzone narzędzia
                    i&nbsp;rozwiązania, które zapewnią pełne bezpieczeństwo
                    i&nbsp;ochronę danych. Skuteczną ochronę potwierdzimy
                    certyfikatem zgodności.
                  </Styled.Text>
                </Styled.TextBox>
              )}

              {active === 3 && (
                <Styled.TextBox {...textAnimation} key="fourth">
                  <Header as={"h2"}>
                    <Underlined>Transparentność</Underlined>
                  </Header>
                  <Styled.Text>
                    Dzięki szczegółowym, comiesięcznym raportom czarno
                    na&nbsp;białym zobaczysz, co&nbsp;zyskujesz dzięki naszej
                    obsłudze, łącznie z&nbsp;czasem reakcji i&nbsp;ocenami,
                    które otrzymujemy od&nbsp;Twoich pracowników. Możesz
                    na&nbsp;bieżąco sprawdzać status zgłoszeń i&nbsp;patrzeć nam
                    na&nbsp;ręce, mając pewność, że&nbsp;serio robimy
                    to&nbsp;dobrze.
                  </Styled.Text>
                </Styled.TextBox>
              )}
            </AnimatePresence>
          </Styled.Box>

          <AnimatePresence exitBeforeEnter>
            {active === 0 && (
              <Styled.ImageBox {...imageAnimation} key="first-image">
                <Elastic />
              </Styled.ImageBox>
            )}

            {active === 1 && (
              <Styled.ImageBox {...imageAnimation} key="second-image">
                <Standard />
              </Styled.ImageBox>
            )}

            {active === 2 && (
              <Styled.ImageBox {...imageAnimation} key="third-image">
                <Security />
              </Styled.ImageBox>
            )}

            {active === 3 && (
              <Styled.ImageBox {...imageAnimation} key="fourth-image">
                <Transparency />
              </Styled.ImageBox>
            )}
          </AnimatePresence>
        </Styled.MainWrapper>
      </Styled.Container>
    </Styled.Section>
  )
}

export default BenefitsSection
