import styled, { css } from "styled-components"
import { AppContainer, mediaUp } from "../../../../styles/Mixins"
import ThemeStyle from "../../../../styles/ThemeStyle"
import Button from "../../../Button/Button"

export const Menu = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: transparent;

  ${mediaUp(ThemeStyle.breakpoints.md, css``)};
`

export const Container = styled.div`
  ${AppContainer};
  padding: 0rem 0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Links = styled.ul`
  display: none;

  ${mediaUp(
    ThemeStyle.breakpoints.md,
    css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 40%;
      height: 100%;
    `
  )};
`

export const LogoLink = styled.div`
  display: block;
  cursor: none;
  padding: 1rem 1rem 1rem 0rem;
  transition: ${ThemeStyle.transition};
  &:hover {
    color: ${ThemeStyle.colors.main};
  }
`

export const Link = styled.a`
  display: block;
  cursor: none;
  margin: 0 1rem;
  transition: ${ThemeStyle.transition};
  height: 5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 15rem;
  &:hover {
    color: ${ThemeStyle.colors.main};
  }
`

export const SubscribeButton = styled(Button)`
  display: none;

  ${mediaUp(
    ThemeStyle.breakpoints.md,
    css`
      font-size: 1.6rem;
      padding: 2rem 4rem;
      display: inline-block;
    `
  )};
`

export const MenuButton = styled.button`
  display: inline-block;
  border: none;
  outline: none;

  ${mediaUp(
    ThemeStyle.breakpoints.md,
    css`
      display: none;
    `
  )};
`
