import React, { FunctionComponent } from "react"
import * as Styled from "./FaqAfterSection.styled"
import { StaticImage } from "gatsby-plugin-image"
import { motion, useViewportScroll, useTransform } from "framer-motion"

type Props = {}

const containerVariants = {
  hidden: {
    opacity: 0,
    transition: {
      staggerChildren: 0.25,
    },
  },

  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      when: "beforeChildren",
    },
  },
}

const imageVariants = {
  visible: {
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
}

const FaqAfterSection: FunctionComponent<Props> = props => {
  const { scrollYProgress } = useViewportScroll()
  const paralaxSpeeds: number[] = [-3, -2, 0.25, 1.05]
  const paralaxTransforms = paralaxSpeeds.map(speed =>
    useTransform(scrollYProgress, [0, 0.8, 1], [0, 100, 100 * speed])
  )
  const paralaxImageScaling = paralaxSpeeds.map(speed =>
    useTransform(scrollYProgress, [0, 0.8, 1], [0, 1, 1.1 * speed])
  )

  return (
    <Styled.Section>
      <Styled.Background />
      <Styled.Container
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
      >
        <Styled.ImageWrapperOne
          variants={imageVariants}
          style={{ y: paralaxTransforms[0] }}
        >
          <StaticImage
            width={820}
            src={
              "../../../images/FaqAfterSection/smiling-man-working-on-laptop.png"
            }
            alt={"Uśmięchnięty mężczyzna w biurze pracujący na laptopie"}
            placeholder={"blurred"}
          />
        </Styled.ImageWrapperOne>
        <Styled.ImageWrapperTwo
          variants={imageVariants}
          style={{ y: paralaxTransforms[1] }}
        >
          <StaticImage
            width={600}
            src={
              "../../../images/FaqAfterSection/group-of-persons-coworking.png"
            }
            alt={"Grupa ludzi pracująca w strefie coworkingowej"}
            placeholder={"blurred"}
          />
        </Styled.ImageWrapperTwo>
        <Styled.ImageWrapperThree
          variants={imageVariants}
          style={{ y: paralaxTransforms[2], scale: paralaxImageScaling[3] }}
        >
          <StaticImage
            width={410}
            src={"../../../images/FaqAfterSection/man-working-on-tablet.png"}
            alt={"Mężczyzna w biurze pracujący na tablecie"}
            placeholder={"blurred"}
          />
        </Styled.ImageWrapperThree>
      </Styled.Container>
    </Styled.Section>
  )
}

export default FaqAfterSection
