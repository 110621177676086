import React, { FunctionComponent } from "react"

type Props = {}

const Padlock: FunctionComponent<Props> = props => {
  return (
    <svg
      width="27"
      height="40"
      viewBox="0 0 27 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        style={{ stroke: "none" }}
        d="M13.3332 30C11.4932 30 9.99991 28.5067 9.99991 26.6667C9.99991 24.8267 11.4932 23.3333 13.3332 23.3333C15.1732 23.3333 16.6666 24.8267 16.6666 26.6667C16.6666 28.5067 15.1732 30 13.3332 30ZM13.3332 13.3333C11.5632 13.3333 9.87824 13.685 8.33324 14.3117V8.33333C8.33324 5.57667 10.5766 3.33333 13.3332 3.33333C16.0899 3.33333 18.3332 5.57667 18.3332 8.33333V10H21.6666V8.33333C21.6666 3.73167 17.9366 0 13.3332 0C8.72824 0 4.99991 3.73167 4.99991 8.33333V16.2683C1.95491 18.7117 -9.15527e-05 22.4583 -9.15527e-05 26.6667C-9.15527e-05 34.03 5.96991 40 13.3332 40C20.6966 40 26.6666 34.03 26.6666 26.6667C26.6666 19.3033 20.6966 13.3333 13.3332 13.3333ZM13.3332 36.6667C7.81157 36.6667 3.33324 32.1883 3.33324 26.6667C3.33324 21.145 7.81157 16.6667 13.3332 16.6667C18.8549 16.6667 23.3332 21.145 23.3332 26.6667C23.3332 32.1883 18.8549 36.6667 13.3332 36.6667Z"
        fill="#363636"
      />
    </svg>
  )
}

export default Padlock
