import styled, { css } from "styled-components"
import {
  AppContainer,
  AppSection,
  DesktopHidden,
  mediaUp,
  MobileHidden,
} from "../../../styles/Mixins"
import ThemeStyle from "../../../styles/ThemeStyle"
import { motion } from "framer-motion"

export const AnimatedImageDiv = styled(motion.div)``
export const Section = styled.section`
  ${AppSection};
  position: relative;
  background-color: #fcfcfc;
  z-index: 1;
  overflow-x: hidden;
`

export const SecondBackground = styled.div`
  ${AppSection};
  background-color: #ffffff;
  height: 20rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: -1;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      height: 12rem;
    `
  )}
`

export const Container = styled.div`
  ${AppContainer};
  box-sizing: border-box;
  margin-right: 0;
  //overflow-x: hidden;
  overflow-y: visible;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      height: auto;
      //max-width: 900rem;
    `
  )}
`

export const ImageDesktop = styled.div`
  ${MobileHidden};
  width: calc(100% + 10rem);

  ${mediaUp(
    ThemeStyle.breakpoints.xxl,
    css`
      width: calc(100% + (100vw - 144rem) / 2);
    `
  )}
`

export const ImageMobile = styled.div`
  ${DesktopHidden};
`
