import React, { FunctionComponent } from "react"
import * as Styled from "./Menu.styled"
import LogoIcon from "../../../../icons/LogoIcon"
import BurgerMenuIcon from "../../../../icons/BurgerMenuIcon"
import menuLinks from "./MenuLinks"
import CursorContext from "../../../CustomCursor/context/CursorContext"
import useScrollTo from "../../../../hooks/useScrollTo"

interface MenuProps {
  handleMenuToggle: (event: React.MouseEvent<HTMLButtonElement>) => void
  isMenuOpen: boolean
  disableAnimation: () => void
}

const Menu: FunctionComponent<MenuProps> = ({
  handleMenuToggle,
  isMenuOpen,
  disableAnimation,
}) => {
  const { events } = React.useContext(CursorContext)
  const scrollToForm = useScrollTo("formularz")
  const scrollToHome = useScrollTo("start")

  return (
    <Styled.Menu>
      <Styled.Container>
        <Styled.LogoLink
          {...events}
          onClick={() => {
            scrollToHome()
            disableAnimation()
          }}
        >
          <LogoIcon />
        </Styled.LogoLink>
        <Styled.Links>
          {menuLinks.map((link, index) => (
            <Styled.Link key={index} href={link.address} {...events}>
              {link.title}
            </Styled.Link>
          ))}
        </Styled.Links>
        <Styled.SubscribeButton onClick={scrollToForm}>
          Subskrybuj
        </Styled.SubscribeButton>
        <Styled.MenuButton onClick={handleMenuToggle}>
          <BurgerMenuIcon open={isMenuOpen} />
        </Styled.MenuButton>
      </Styled.Container>
    </Styled.Menu>
  )
}

export default Menu
